import React, {useState, useEffect} from 'react';
import './PathwayHero.scss';
import * as Widgets from '../../../widgets';
import pointingHand from '../../../assets/images/svg/Vector Assets-05.svg';
import EntryBadge from '../../../assets/images/svg/Vector Assets-01.svg';
import CareerBadge from '../../../assets/images/svg/Vector Assets-04.svg';
import AdvancementBadge from '../../../assets/images/svg/Vector Assets-02.svg';
import {Button} from '../../../widgets';
import SlideAnimation from '../../../animations/SlideAnimation';
/**
 *
 * @param {props.title} Pathway Title
 * @param {props.pathway} Pathway Data
 * @param {props.summary} Pathway Summary
 * @param {props.level} Pathway Occupation Level
 * @param {props.pdfData} PDF Data
 * @param {props.jobs} Pathway Jobs List
 * @params {props.[imageName]} Pathway Hero Image 
 * @returns Pathway Hero for each Pathway Page based on props.level
 */
export default function PathwayHero(props) {
  const occupation = props.title;
  const summary = JSON.parse(props.summary);
  const [pathwayLevel, setPathwayLevel] = useState('');
  var count = 0;
  useEffect(() => {
    if (props.level !== undefined) {
      setPathwayLevel(JSON.parse(props.level));
    }
    return () => {
      setPathwayLevel('');
    };
  }, [props.level]);
  // Clean PDF Data
  const cleanPdfs = [];
  if (props.pdfData.length > 0) {
    props.pdfData.forEach((prov) => {
      cleanPdfs.push(prov);
    });
  }
  return (
    <div className={'Pathway-Hero-Container'}>
      <img
        alt='Pathway'
        className={'Pathway-Hero-Image'}
        src={
          // Focus on this
          pathwayLevel === 'Entry'
            ? props.entryHeroImage
            : pathwayLevel === 'Career'
              ? props.careerHeroImage
              : pathwayLevel === 'Advanced'
                ? props.advancedHeroImage
                : pathwayLevel === 'Green Career'
                  ? props.careerHeroImage
                  : props.entryHeroImage
        }
      />
      <div className={'Pathway-Hero-Heading-Container'}>
        <Widgets.Badge
          scrub
          scrollTrigger
          rotationStart={'-10deg'}
          imgSrc={
            pathwayLevel === 'Entry'
              ? EntryBadge
              : pathwayLevel === 'Career'
                ? CareerBadge
                : pathwayLevel === 'Advanced'
                  ? AdvancementBadge
                  : pathwayLevel === 'Green Career'
                    ? CareerBadge
                    : EntryBadge
          }
          containerClassName={'Pathway-Hero-Badge-Left-1'}
          imgClassName={'Pathway-Hero-Badge'}
        />
        <p className={'Pathway-Hero-Sub-Heading'}>{pathwayLevel} Pathway</p>
        <p className={'Pathway-Hero-Heading'}>{occupation.replace(/\([^()]*\)/g, '')}</p>
        <p className={'Pathway-Hero-Summary'}>{summary}</p>
        <div className='Pathway-Hero-Jobs-Container'>
          <div className='Pathway-Hero-Jobs-Heading'>Relevant Job Titles</div>
          <div className='Pathway-Hero-Jobs'>{JSON.parse(props.jobs)}</div>
        </div>
        <SlideAnimation
          xStart={80}
          duration={1}
          node={'Pathway-Hero-Download'}
        >
          <div
            className='Pathway-Hero-Download'
          >
            {cleanPdfs.map((pdf, index) => {
              if (props.pathway.occupation.includes(JSON.parse(pdf.occupation))) {
                const button = (
                  <Button
                    key={index}
                    to={JSON.parse(pdf.url)}
                    buttonType='link'
                    className='Pathway-Hero-Download-Button'
                    text='Download Training Plan'
                  />
                );
                if (count === 0) {
                  count = count + 1;
                  return button;
                } else {
                  return null;
                }
              } else {
                return null;
              }
            })}
          </div>
        </SlideAnimation>
      </div>
      <div className={'Pathway-Hero-SVG-Container'}>
        <img className={'Pathway-Hero-SVG'} src={pointingHand} alt='hand' />
      </div>
    </div>
  );
}

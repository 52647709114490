import React from 'react';
import './HomeHero.scss';
import * as Widgets from '../../../widgets';
import PreloadImage from 'react-preload-image';
import backgroundImage from '../../../assets/images/webp/hero.webp';
import SlideAnimation from '../../../animations/SlideAnimation';
import {HashLink} from 'react-router-hash-link';
/**
 * @returns Home Hero
 */
export default function HomeHero() {
  return (
    <div className={'Home-Hero-Container'}>
      <PreloadImage className={'Home-Hero-Background-Image'}
        src={backgroundImage}
        lazy />
      <div className={'Home-Hero-Heading-Container'}>
        <div className={'Home-Hero-Heading'}>
          <SlideAnimation
            xStart={150}
            duration={0.6}
            node={'Home-Hero-Heading-Block-1'}
          >
            <span className='Home-Hero-Heading-Block-1'>Build</span>
          </SlideAnimation>
          <SlideAnimation
            xStart={150}
            duration={1}
            node={'Home-Hero-Heading-Block-2'}>
            <span className='Home-Hero-Heading-Block-2'>Your</span>
          </SlideAnimation>
          <SlideAnimation             
            xStart={150}
            duration={1.4}
            node={'Home-Hero-Heading-Block-3'}>
            <span className='Home-Hero-Heading-Block-3'>Team.</span>
          </SlideAnimation>
        </div>
        <SlideAnimation
          yStart={200}
          node={'Home-Hero-Sub-Heading'}
        >
          <p className={'Home-Hero-Sub-Heading'}>
            POWERFUL TRAINING SOLUTIONS FOR CAREER PATHWAYS IN MANUFACTURING
          </p>
        </SlideAnimation>
        <Widgets.Badge
          rotationStart={'90deg'}
          triggerStart={'top center'}
          imgSrc={'/assets/png/entry.png'}
          badgeType={'link'}
          linkDestination={'/entry'}
          containerClassName={'Home-Hero-Badge-Left-1'}
          imgClassName={'Home-Hero-Badge-0'}
        />
        <Widgets.Badge
          rotationStart={'90deg'}
          imgSrc={'/assets/png/career.png'}
          badgeType={'link'}
          linkDestination={'/career'}
          containerClassName={'Home-Hero-Badge-Left-2'}
          imgClassName={'Home-Hero-Badge-1'}
        />
        <Widgets.Badge
          rotationStart={'-90deg'}
          imgSrc={'/assets/png/advancement.png'}
          badgeType={'link'}
          linkDestination={'/advancement'}
          containerClassName={'Home-Hero-Badge-Right-1'}
          imgClassName={'Home-Hero-Badge-2'}
        />
        <HashLink smooth to='/#EV-Careers' className='Courses-Hero-Button'>
          <Widgets.Badge
            rotationStart={'-90deg'}
            imgSrc={'/assets/png/evbadgenew.png'}
            badgeType={'badge'}
            containerClassName={'Home-Hero-Badge-Right-2'}
            imgClassName={'Home-Hero-Badge-3'}
          />
        </HashLink>
      </div>
    </div>
  );
}


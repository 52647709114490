import React, {useEffect, useState} from 'react';
import './ProgramLearn.scss';
import AnimateOnScroll from '../../../logic/AnimateOnScroll';
import {Button} from '../../../widgets';

function ProgramLearn(props) {
  /**
   * ProgramLearn Functional Component
   * *This functional component renders the 'What Will You Learn' sections of each Program page.
   * *It utilizes the AnimateOnScroll function with refs and state to tell when an element is in view to initiate it's animation.
   * *Takes in props for specific styling and to populate it's content.
   */
  const [drape, setDrape] = useState(0);
  const [ref, visible] = AnimateOnScroll({rootMargin: '-100px'});
  useEffect(() => {
    if (visible === true) {
      setDrape(1);
    }
    return () => {
      setDrape(0);
    };
  }, [visible]);
  return (
    <div className='Program-Learn-Container' ref={ref} drape={drape}>
      <div
        className='Learn-Content-Left-Container'
        drape={drape}
        ref={ref}
        style={{background: props.leftContainerColor}}
      >
        <div
          className='Learn-Text-Container'
          drape={drape}
          ref={ref}
          style={{height: props.learnContentHeight}}
        >
          <h1 className='Learn-Heading-Text' drape={drape} ref={ref}>
            {props.learnHeading}
          </h1>
          <p className='Learn-Paragraph-Text' drape={drape} ref={ref}>
            {props.learnText}
          </p>
          {props.listOp === true ? (
            <ul className='Learn-List' drape={drape} ref={ref}>
              <div>&#10004; {props.listOp1}</div>
              <div>&#10004; {props.listOp2}</div>
              <div>&#10004; {props.listOp3}</div>
              {props.listOp4 ? <div>&#10004; {props.listOp4}</div> : null}
            </ul>
          ) : null}
          {props.buttonText ? (
            <Button
              className={'Register-Button'}
              buttonType={'link'}
              to={props.buttonDestination}
              text={props.buttonText}
            />
          ) : null}
        </div>
      </div>
      <div
        className='Learn-Content-Right-Container'
        drape={drape}
        ref={ref}
        style={{backgroundImage: `url(${props.rightContainerImage})`}}
      ></div>
    </div>
  );
}

export default ProgramLearn;

import React, {useEffect, useState} from 'react';
import AnimateOnScroll from '../../../logic/AnimateOnScroll';
import {Button} from '../../../widgets';
/**
 * * Call To Action Section
 * This shows a CTA or Call to Action for people to
 * download training manuals for their employees
 * or themselves.
 * @param {props.cleanPdfs} Clean PDF Data
 * @returns A call to action header and button
 */
export default function CallToAction(props) {
  const width = window.innerWidth;
  const [animateaccordion, setanimateaccordion] = useState(0);
  const [ref, visible] = AnimateOnScroll({rootMargin: '-100px'});
  useEffect(() => {
    if (width > 400) {
      if (visible) {
        setanimateaccordion(1);
      } else {
        setanimateaccordion(0);
      }
    }
  }, [visible, width]);
  return (
    <div
      ref={ref}
      animateaccordion={animateaccordion}
      className='Call-To-Action-Section'
    >
      <div className={'Training-Tools-Header'}>
        {props.header}
      </div>
      <div className='Call-To-Action-Button-Container'>
        {props.cleanPdfs ? props.cleanPdfs.map((pdf, index) => {
          if (props.pathway.occupation.includes(pdf.occupation)) {
            return (
              <Button
                key={index}
                to={JSON.parse(pdf.url)}
                buttonType='link'
                className='Call-To-Action-Button'
                text='Download Training Plan'
              />
            );
          } else {
            return null;
          }
        }) : <Button className='Call-To-Action-Button' text='Online Courses' to='/online-courses' />}
      </div>
    </div>
  );
}

import React from 'react';
// Import React Google Analytics
import ReactGA from 'react-ga4';
import RotateAnimation from '../../animations/RotateAnimation';
export default function Badge(props) {
  if (props.badgeType === 'link') {
    return (
      <a onClick={() => {
        ReactGA.event({
          category: 'Button',
          action: 'Clicked Badge',
          label: `${props.containerClassName}, ${props.linkDestination}`,
        });
      }} href={props.linkDestination} className={props.containerClassName}>
        <RotateAnimation
          duration={2}
          node={props.imgClassName}
          rotationStart={
            props.rotationStart ?
              props.rotationStart : false
          }
        >
          <img
            // ref={ref}
            src={props.imgSrc}
            alt='Badge'
            className={props.imgClassName}
          />
        </RotateAnimation>
      </a>
    );
  }
  else {
    return (
      <div className={props.containerClassName}>
        <RotateAnimation
          scrub={props.scrub}
          duration={2}
          node={props.imgClassName}
          rotationStart={
            props.rotationStart ?
              props.rotationStart : false
          }
          triggerStart={
            props.triggerStart ?
              props.triggerStart : false
          }
          scrollTrigger={
            props.scrollTrigger ?
              props.scrollTrigger : false
          }
        >
          <img
            src={props.imgSrc}
            alt='Badge'
            className={props.imgClassName}
          />
        </RotateAnimation>
      </div>
    );
  }
}

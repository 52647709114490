import React from 'react';
import './Carousel.scss';
// Carousel Card
import Card from './Card';
export default function Carousel(props) {
  /**
   * * Carousel Function for rendering Pathway Cards.
   * This function makes cards render in a horizontal scrolling div.
   */
  return (
    <div className={'Carousel'}>
      <div
        className={props.carouselHeader ?
          'Green-Carousel-Header' : 'Carousel-Header'}
      >
        {props.carouselHeader ? props.carouselHeader : props.header}
      </div>
      <div
        className={
          props.scrollWidth === 'wide'
            ? 'Carousel-Container-Wide'
            : 'Carousel-Container'
        }
      >
        <div
          className={
            props.scrollWidth === 'wide'
              ? 'Carousel-Scroll-Wide'
              : 'Carousel-Scroll'
          }
        >
          {props.levelData
            // Career Level Data
            ? props.levelData.map((job) => {
              return <Card level='career' key={job.title} job={job} />;
            })
            // Entry and Advanced Level Data
            : props.data.map((job) => {
              if (props.scrollWidth === 'wide') {
                return <Card level='advanced' key={job.title} job={job} />;
              } else {
                return <Card level='entry' key={job.title} job={job} />;
              }
            })}
        </div>
      </div>
    </div>
  );
}

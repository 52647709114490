import React from 'react';
import {SVGLine} from '../../../widgets';
import './Credentials.scss';
/**
 *
 * @param {props.pathway} Pathway Data
 * @returns Credentials List with clickable links to courses
 */
export default function Credentials(props) {
  // Parse Credential Data from Pathway Data
  const credentials = JSON.parse(props.pathway.credentialData);
  return (
    <div className='Credentials-Container'>
      <div className='Credentials-Header'>Available Credentials</div>
      <SVGLine
        lineClassName='Credentials-Green-Line'
        svgClassName='SVG-Credentials-Green-Line'
        points={'0 0, 0 200'}
      />
      <div className='Credentials-Column'>
        <div className='Credentials-List'>
          {credentials.map((cred, index) => {
            return (
              <a
                target='_blank'
                rel='noopener noreferrer'
                href={cred.requirements}
                key={index}
                className='Credential-Container'
              >
                <div className='Credential-Title'>{cred.credential}</div>
                <div className='Credential-Body'>{cred.credentialingBody}</div>
              </a>
            );
          })}
        </div>
        <div className={'See-More-Button'}>&#x2193;See More&#x2193;</div>
        <div className='Credentials-Disclaimer'>
          *This is not an exhaustive list of available credentials. The industry
          recognized certifications and credentials listed here are identified
          by the Skills Certification System of the Manufacturing Institute and
          are endorsed by the National Association of Manufacturers.
        </div>
      </div>
      <svg className='Credentials-Triangle'>
        <path
          d='
          M 145, 0
          L 145, 360
          L 10, 175
        '
          className='Triangle-Path'
        />
      </svg>
    </div>
  );
}

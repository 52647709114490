import React, {useState, useEffect} from 'react';
/**
 *
 * @returns SVG Bullet for Overview Section
 */
export default function SVGBullet() {
  const [cx, setCX] = useState();
  const [cy, setCY] = useState();
  const [radius, setRadius] = useState();
  const width = window.innerWidth;
  useEffect(() => {
    setCX(width / 80);
    setCY(width / 80);
    setRadius(width / 80);
  }, [cx, cy, radius, width]);
  return (
    <svg className='Bullet-SVG' focusable={true}>
      <circle cx={cx} cy={cy} r={radius}></circle>
    </svg>
  );
}

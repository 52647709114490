import React, {useEffect, useState} from 'react';
// Import Loading Screen
import LoadingPage from '../LoadingPage';
import {Navigator} from '../../components/';
import './PathwayPage.scss';
import * as PageComponents from '../../components/Pathway/';
import {JumbotronPhoto} from '../../components';
import CallToAction from '../../components/Pathway/AccordionBoard/CallToAction';
import ReactGA from 'react-ga4';
// hero images
import entryHeroImage from '../../assets/images/webp/pexels-elevate-1267324.webp';
import careerHeroImage from '../../assets/images/webp/advancementherobackground.webp';
import advancedHeroImage from '../../assets/images/webp/careerpageherobackground.webp';
/**
 *
 * @param {props.pathway} Pathway Data
 * @param {props.title} Pathway Occupation Title
 * @param {props.pdfData} PDF Data
 * @returns Pathway Pages Dynamically with
 * data from our MongoDB.
 */
export default function PathwayPage(props) {
  useEffect(() => {
    var images = [entryHeroImage, careerHeroImage, advancedHeroImage];
    if (!cachingImages) {
      setCachingImages(true);
      cacheImages(images);
    }
    // Cleanup on unmount
    return () => {
      setCachingImages(false);
      images = [];
    };
  }, []);
  const [cachingImages, setCachingImages] = useState(false);
  const cacheImages = async (appImages) => {
    var promises = await appImages.map(async (image) => {
      return new Promise(function(resolve, reject) {
        const img = new Image();
        img.src = image;
        img.onload = () => {
          resolve();
        };
        img.onerror = () => {
          reject();
        };
      });
    });
    await Promise.all(promises).then(() => {
      setCachingImages(false);
    });
  };
  function PageContent() {
    ReactGA.send({hitType: 'pageview', page: `/${props.title}`, title: JSON.parse(props.title)});
    return (
      <div className={'Pathway-Page-Container'}>
        <Navigator backgroundColor={'white'} />
        <PageComponents.PathwayHero
          title={JSON.parse(props.title)}
          summary={props.summary}
          jobs={props.pathway.jobTitles}
          level={props.pathway.level}
          pathway={props.pathway}
          pdfData={props.pdfData}
          entryHeroImage={entryHeroImage}
          careerHeroImage={careerHeroImage}
          advancedHeroImage={advancedHeroImage}
        />
        {/* Pathway Body */}
        <PageComponents.Overview pathway={props.pathway} />
        <PageComponents.CircleChart pathway={props.pathway} />
        <PageComponents.AccordionBoard
          resources={props.resources}
          pdfData={props.pdfData}
          pathway={props.pathway}
        />
        <PageComponents.Wages pathway={props.pathway} />
        <PageComponents.Schedule pathway={props.pathway} />
        <PageComponents.Credentials pathway={props.pathway} />
        <PageComponents.Providers pathway={props.pathway} />
        <CallToAction header={'Looking For Online Courses?'} />
        {/* ------------ */}
        <JumbotronPhoto
          buttonDestination={`/${
            JSON.parse(props.pathway.level) === 'Advanced'
              ? 'advancement'
              : JSON.parse(props.pathway.level)
          }`}
          headerText={`Search ${JSON.parse(props.pathway.level)} Pathways`}
          buttonText={`See ${JSON.parse(props.pathway.level)} Pathways`}
          data={{
            header: `Search ${JSON.parse(props.pathway.level)} Pathways`,
            paragraph:
            'Ad minim laboris magna veniam est aliqua cupidatat proident minim labore tempor Lorem enim. Incididunt Lorem in pariatur in duis nisi ex quis laborum ut.',
          }}
          sectionClassName={'Jumbotron-Photo-3'}
        />
      </div>
    );
  }
  if (cachingImages) {
    return <LoadingPage />;
  } else if (!cachingImages) {
    return <PageContent />;
  }
}

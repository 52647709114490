import React, {useEffect, useState} from 'react';
import AnimateOnScroll from '../../../logic/AnimateOnScroll';
import {Button, Carousel} from '../../../widgets';
import './BodySection.scss';
/**
 *
 * @param {*} props
 * @returns Body Sections based
 * on props.alignment and props.levelOccupations
 */
export default function BodySection(props) {
  const [slideinsection, setslideinsection] = useState(0);
  const [ref, visible] = AnimateOnScroll({rootMargin: '150px'});
  useEffect(() => {
    if (visible) {
      setslideinsection(1);
    } else {
      setslideinsection(0);
    }
  }, [visible]);
  if (props.levelOccupations) {
    /**
     * Clean Occupations by Level
     */
    var cleanLevelOccupations = props.levelOccupations.map((occ) => {
      return {
        title: JSON.parse(occ.occupation),
        image: `${JSON.parse(occ.occupation)
          .toLowerCase()
          .replace(/[{}]/g, '')
          .replace(/\s/g, '-')}}`,
      };
    });
    var cleanGreenOccupations = props.greenOccupations.map((occ) => {
      return {
        title: JSON.parse(occ.occupation),
        image: `${JSON.parse(occ.occupation)
          .toLowerCase()
          .replace(/[{}]/g, '')
          .replace(/\s/g, '-')}}`,
      };
    });
  }
  if (props.alignment === 'right') {
    // console.log(props.sectionData);
    return (
      <section className={props.sectionClassName}>
        <div
          className='Section-1-Left'
          ref={ref}
          slideinsection={slideinsection}
        />
        <div className='Section-1-Right'>
          <h1 className={props.headerClassName}>{props.header}</h1>
          <p className='Section-Paragraph'>{props.sectionData.paragraph}</p>
          <Button
            to={props.header === 'Entry' ? '/entry' : '/career'}
            text={props.buttonText}
            className={props.buttonClassName}
          />
          <Carousel
            header={props.carouselHeader}
            scrollWidth={props.carouselWidth}
            // {
            //   title: 'Supervisor/Foreman',
            //   image: 'foreman',
            //   summary:
            //     'Pariatur duis cupidatat ad laboris. Irure aute officia et sit laboris et mollit ad minim duis in reprehenderit eiusmod occaecat. Tempor aute fugiat elit id excepteur quis esse reprehenderit qui.',
            // },
            data={props.sectionData.professions}
            levelData={cleanLevelOccupations}
          />
          {props.header === 'Career' ?    
            <div className='Green-Carousel'>
              <Carousel
                carouselHeader={'EV Career Pathways'}
                header={props.carouselHeader}
                scrollWidth={props.carouselWidth}
                data={props.sectionData.professions}
                levelData={cleanGreenOccupations}
              />
            </div>  
            : null
          }
        </div>
      </section>
    );
  } else if (props.alignment === 'left') {
    return (
      <section className={props.sectionClassName}>
        <div
          className='Section-2-Left'
          ref={ref}
          slideinsection={slideinsection}
        >
          <h1 className={props.headerClassName}>{props.header}</h1>
          <p className='Section-Paragraph'>{props.sectionData.paragraph}</p>
          <Button
            to={'/advancement'}
            text={props.buttonText}
            className={props.buttonClassName}
          />
          <Carousel
            header={props.carouselHeader}
            scrollWidth={props.carouselWidth}
            data={props.sectionData.professions}
          />
        </div>
        <div className='Section-2-Right' />
      </section>
    );
  }
}

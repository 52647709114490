import React from 'react';
import HubspotForm from 'react-hubspot-form';
import './ContactPage.scss';
import {Navigator} from '../../components';
import SlideAnimation from '../../animations/SlideAnimation';
import ReactGA from 'react-ga4';
/**
 *
 * @returns Contact Page
 * Shows a Contact Page with a HubSpot Form
 * If the form doesn't load,
 * it will show 'Loading...'.
 */
function ContactPage() {
  ReactGA.send({hitType: 'pageview', page: '/contact', title: 'Contact'});
  return (
    <div className='Contact-Page-Container'>
      <Navigator backgroundColor='white' />
      <SlideAnimation
        scrollTrigger={false}
        yStart={1000}
        xStart={0}
        duration={1.1}
        node={'Contact-Summary-Section'}
      >
        <div className='Contact-Summary-Section'>
          <div className='Contact-Header'>Contact Us</div>
          <div className='Contact-Summary-Text'>
          Do you still have questions? Do you need a little guidance with
          developing a more customized approach to training or developing your
          team?
          </div>
          <div className='Contact-Help'>Let Us Help &#8594;</div>
        </div>
      </SlideAnimation>
      <SlideAnimation
        duration={2}
        scrollTrigger={false}
        yStart={1000}
        xStart={0}
        node={'Contact-Form'}
      >
        <div className='Contact-Form'>
          <HubspotForm
            portalId='403737'
            formId='4a0c286e-669b-470e-9b5d-4618461547a4'
            region='na1'
            onSubmit={() => console.log('Submitted!')}
            loading={<div>Loading...</div>}
          />
        </div>
      </SlideAnimation>
    </div>
  );
}
export default ContactPage;

import React, {useEffect} from 'react';
import './ProgramPathways.scss';
import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';

function ProgramPathways(props) {
  /**
   * ProgramPathways Functional Component
   * *This functional component renders the text section below the hero of each Program page.
   * *Utilizes props passed into it for some specific styling and to populate content.
   * *Uses the AnimateOnScroll with refs and a state to initialize animations with gsap for specific elements.
   */

  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    if(window.innerWidth > 800){
      gsap.timeline({
        scrollTrigger: {
          trigger: '#progPathContainer',
          start: 'bottom bottom+=100px',
          end: 'top top-=700px',
          scrub: true,
        }
      }).fromTo('#reveal', {translateY: '0', transform: 'scale(0)', force3D: false}, {translateY: '70px', transform: 'scale(3)', force3D: false});
    }
    return(() => {
      gsap.killTweensOf('#reveal');
    });
  });

  return (
    <div className='Program-Path-Container' id='progPathContainer'>
      <div
        className='Program-Path-Content-Container'
      >
        <h2 className='Program-Path-Heading'>{props.pathwayHeading1}</h2>
        <h1
          className='Program-Path-Heading-2'
          style={{color: props.heading2Color}}
        >
          {props.pathwayHeading2}
        </h1>
        <p className='Program-Path-Text'>{props.pathText}</p>
        <div className='Down-Arrow' id='reveal'>&#8595;</div>
      </div>
    </div>
  );
}

export default ProgramPathways;

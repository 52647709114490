import React, {useState, useEffect} from 'react';
import AnimateOnScroll from '../../../logic/AnimateOnScroll';
import {Button} from '../../../widgets';
import './Paragraph.scss';
import PreloadImage from 'react-preload-image';
/**
 *
 * @param {props.containerClassName} Container Class
 * @param {props.leftContainerClassName} Left Column Class
 * @param {props.rightContainerClassName} Right Column Class
 * @param {props.headerClassName} Header Class Name
 * @param {props.paragraphClassName} Paragraph Class Name
 * @param {props.buttonClassName} Button Class Name
 * @param {props.buttonText} Text for Button
 * @param {props.buttonDestination} Destination for Button
 * @param {props.imgClassName} Image Class Name
 * @param {props.imgSrc} Source for Image
 * @param {props.imgAlt} Alternative for Image
 *
 * @returns Paragraph Section based on parameters
 */
const Paragraph = (props) => {
  const [slideinparagraph, setslideinparagraph] = useState(0);
  const [ref, visible] = AnimateOnScroll({rootMargin: '-50px'});
  useEffect(() => {
    if (visible) {
      setslideinparagraph(1);
    } else {
      setslideinparagraph(0);
    }
  }, [visible]);
  return (
    <div className={props.containerClassName}>
      <div
        ref={ref}
        slideinparagraph={slideinparagraph}
        className={props.leftContainerClassName}
      >
        <div className={props.headerClassName}>{props.header}</div>
        <p className={props.paragraphClassName}>{props.paragraph}</p>
        <Button
          to={props.buttonDestination}
          text={props.buttonText}
          className={props.buttonClassName}
        />
      </div>
      <div className={props.rightContainerClassName}>
        <PreloadImage
          lazy
          className={props.imgClassName}
          src={props.imgSrc}
          alt={props.imgAlt}
        />
      </div>
    </div>
  );
};

export default Paragraph;

import React, {Component} from 'react';
import * as AboutComps from '../../components/About';
import {Navigator} from '../../components/';
import LoadingPage from '../LoadingPage';
import ReactGA from 'react-ga4';
export default class AboutPage extends Component {
  /**
   * About Page Class Component
   * *This component renders the About Page.
   * *The AboutPage component receives props that consist of all page data that is then condensed to data specific for the page.
   * *Utilizes these props to send specific page data to the components it uses to then populate the page.
   * *Uses a simple if conditional to check if the data is coming through, if not, it displays the loading screen. 
   */
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    ReactGA.send({hitType: 'pageview', page: '/about', title: 'About'});
    var filteredData = this.props.pageData.filter((page) => {
      return page.page === '"About"';
    });
    var aboutPageData = filteredData[0];
    if(aboutPageData){
      return (
        <div>
          <Navigator />
          <AboutComps.AboutHero
            aboutHeroH='BEHIND CAREERS IN MANUFACTURING'
            aboutHeroP={JSON.stringify(JSON.parse(aboutPageData.paragraphs)[0][1]).split('\\n')[0].replace(/["']/g, '')}
          />
          <AboutComps.AboutStory
            aboutStoryText={JSON.stringify(JSON.parse(aboutPageData.paragraphs)[0][1]).split('\\n')[2]}
          />
          <AboutComps.AboutHelp
            helpHeading='How we help'
            helpTextLeft={JSON.stringify(JSON.parse(aboutPageData.paragraphs)[0][1]).split('\\n')[4]}
            listItemOne={JSON.stringify(JSON.parse(aboutPageData.paragraphs)[0][1]).split('\\n')[6]}
            listItemTwo={JSON.stringify(JSON.parse(aboutPageData.paragraphs)[0][1]).split('\\n')[7]}
            listItemThree={JSON.stringify(JSON.parse(aboutPageData.paragraphs)[0][1]).split('\\n')[8]}
            listItemFour={JSON.stringify(JSON.parse(aboutPageData.paragraphs)[0][1]).split('\\n')[9]}
          />
          <div className='Program-Bottom-Bar' />
          <AboutComps.AboutJumbo />
        </div>
      );
    } else if (!aboutPageData){
      return <LoadingPage />;
    }
  }
}


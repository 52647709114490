import React from 'react';
import './AppLogo.scss';
import ReactGA from 'react-ga4';
export default function AppLogo(props) {
  const onClickButton = () => {
    ReactGA.event({
      category: 'Navigation',
      action: 'Clicked Navigator Button',
      label: 'Career Pathways Logo',
    });
  };
  var boldTextStyle =
    props.backgroundColor === 'white'
      ? 'Logo-Bold-Text-Blue'
      : 'Logo-Bold-Text';
  var textStyle =
    props.backgroundColor === 'white' ? 'Logo-Text-Blue' : 'Logo-Text';
  var logoLinkStyle =
    props.backgroundColor === 'white' ? 'Logo-Link-Blue' : 'Logo-Link-White';
  return (
    <a onClick={onClickButton} className={logoLinkStyle} href='/'>
      <div className={'App-Logo-Container'}>
        <img className={'Logo-Image'} alt={'Logo'} src={props.imageSrc} />
        <span className={boldTextStyle}>Career</span>
        <span className={boldTextStyle}>Pathways</span>
        <span className={textStyle}>in</span>
        <span className={boldTextStyle}>Manufacturing</span>
      </div>
    </a>
  );
}

import React, {useEffect} from 'react';
import './AboutJumbo.scss';
import {gsap} from 'gsap';

function AboutJumbo() {
  /**
   * About Jumbo Functional Component
   * *This functional component renders the Jumbotron above the footer for the About page.
   * *Now features gsap to animate it's contents and animates on a scrollTrigger that fires when the reference is in view.
   */

  useEffect(() => {
    if(window.innerWidth > 600){
      gsap.timeline({
        scrollTrigger: {
          trigger: '#aboutJumboContainer',
          start: 'center center+=400',
          end: 'top top-=300',
          scrub: 2,
        }
      }).fromTo('#aboutJumboTextPod',
        {translateX: '40vw'},
        {translateX: '2vw'}, 'start').fromTo('#colorChangeText', {color: '#b2d233'}, {color: '#00a8e5'}, 'start');
      return () => {
        gsap.killTweensOf('#aboutJumboTextPod', '#colorChangeText');
      };
    } else if (window.innerWidth < 600) {
      gsap.timeline({
        scrollTrigger: {
          trigger: '#aboutJumboContainer',
          start: 'center center+=1000',
          end: 'top top-=300',
          scrub: 1,
        }
      }).fromTo('#aboutJumboTextPod',
        {translateX: '40vw'},
        {translateX: '2vw'}, 'start').fromTo('#colorChangeText', {color: '#b2d233'}, {color: '#00a8e5'}, 'start');
      return () => {
        gsap.killTweensOf('#aboutJumboTextPod', '#colorChangeText');
      };
    }
  });

  return (
    <div className='About-Jumbo-Container' id='aboutJumboContainer'>
      <div
        className='About-Jumbo-Text-Container'
      >
        <div className='About-Jumbo-Text-Pod' id='aboutJumboTextPod'>
          <p className='About-Jumbo-Text'>
            THE FOURTH INDUSTRIAL REVOLUTION IS HERE,
          </p>
          <p className='About-Jumbo-Text-Blue' id='colorChangeText'>
            AND WORKFORCE IS OUR PRIORITY.
          </p>
        </div>
      </div>
    </div>
  );
}

export default AboutJumbo;

import React, {useEffect, useState} from 'react';
import './Providers.scss';
/**
 *
 * @param {props.pathway} Pathway Data
 * @returns Grid of Providers for Each Pathway Page
 */
export default function Providers(props) {
  const [providerData, setProviderData] = useState([]);
  const providers = JSON.parse(props.pathway.rtiProviderData);
  useEffect(() => {
    getProviderData();
    return () => {
      clearData();
    };
  }, []);
  const clearData = () => {
    setProviderData([]);
  };
  // Get Provider Regions
  const getProviderData = async () => {
    var url =
      'https://us-east-1.aws.data.mongodb-api.com/app/career-pathways-mqcqx/endpoint/providers_data';
    try {
      await fetch(url, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((response) => {
        const data = response.json();
        data.then((prov) => {
          setProviderData((d) => [...d, prov]);
        });
      });
    } catch (error) {
      console.log('Failed to Fetch', error);
    }
  };
  const cleanRegions = [];
  // Add To Clean Providers
  if (providerData.length > 0) {
    providerData.forEach((prov) => {
      prov.forEach((p) => {
        cleanRegions.push(JSON.parse(p.provider));
      });
    });
  }
  // Join Data
  var joinedData = [];
  providers.forEach((prov) => {
    return cleanRegions.forEach((region) => {
      if (
        region[1].includes('Southern Illinois University') &&
        prov.provider.includes('Southern Illinois University')
      ) {
        joinedData.push({
          provider: prov.provider,
          program: prov.programWebsite,
          region: region[0],
        });
      } else if (
        region[1].includes(prov.provider) ||
        prov.provider.includes(region[1])
      ) {
        joinedData.push({
          provider: prov.provider,
          program: prov.programWebsite,
          region: region[0],
        });
      }
    });
  });
  // Reduce Joined Data
  var reducedData = joinedData.reduce((b, c) => {
    return (
      (
        b[b.findIndex((d) => d.provider === c.provider)] ||
        b[
          b.push({
            count: 0,
            region: c.region,
            provider: c.provider,
            programWebsite: c.program,
          }) - 1
        ]
      ).count++,
      b
    );
  }, []);
  // Group Reduced Data
  var groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };
  let groupedData = groupBy(reducedData, 'region');
  let regions = Object.keys(groupedData);
  return (
    <div className='Providers-Container'>
      <div className='Providers-Header-Container'>
        <div className='Providers-Header'>RTI Providers</div>
        <div className='Providers-Summary'>
          The below list of providers has curriculum and coursework that aligns
          with the core skills and competencies for this occupation.
          Considerations must be made by the employer and the{' '}
          {JSON.parse(props.pathway.level) === 'Career'
            ? 'apprentice'
            : 'worker'}{' '}
          in regards to location, class schedule, and cost.
        </div>
      </div>
      <ul className='Providers-List'>
        {regions.map((group) => {
          return (
            <div key={group} className='Region'>
              <div className='Region-Title'>{group}</div>
              {reducedData.map((provider, index) => {
                if (provider.region === group) {
                  return (
                    <a
                      target='_blank'
                      rel='noopener noreferrer'
                      key={index}
                      className='Providers-List-Item-Link'
                      href={`${provider.programWebsite}`}
                    >
                      <li className='Providers-List-Item'>
                        {provider.provider}
                      </li>
                    </a>
                  );
                } else {
                  return null;
                }
              })}
            </div>
          );
        })}
      </ul>
      {/* <div className='Providers-Disclaimer'>
        *This is not an exhaustive list of available RTI providers in the
        various regions. If you have questions or would like to add a provider
        to this list, please contact us for support.
      </div> */}
    </div>
  );
}

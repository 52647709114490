import React, {useState, useEffect} from 'react';
import AnimateOnScroll from '../../../logic/AnimateOnScroll';
import './Wages.scss';
/**
 *
 * @param {props.pathway} Pathway Data
 * @returns Wages Section for Pathway Pages
 */
export default function Wages(props) {
  const [animatewages, setanimatewages] = useState(0);
  const [ref, visible] = AnimateOnScroll({rootMargin: '-200px'});
  useEffect(() => {
    if (visible) {
      setanimatewages(1);
    } else {
      setanimatewages(0);
    }
  }, [visible]);
  var currency = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  var numberCommas = new Intl.NumberFormat('eng-US', {
    maximumSignificantDigits: 3,
  });
  const wageData = JSON.parse(props.pathway.wageData)[0];
  return (
    <div className='Wages-Container'>
      <div ref={ref} animatewages={animatewages} className='Wages-Column-Left'>
        <div className='Wages-Header'>Wages</div>
        <div className='Wages-Summary'>
          This is a general idea of what wages look like throughout this career
          path in Illinois.
        </div>
        <div className='Wages-Details'>
          *Number of jobs in the state of Illinois are estimates and do not
          include self-employed workers or those that combine occupation with
          other roles.
        </div>
        <div className='Employment-Circle'>
          <div className='Employment-Circle-Header'>Employment*</div>
          <div className='Employment-Circle-Data'>
            {numberCommas.format(wageData.wages[0])}
          </div>
        </div>
      </div>
      <div ref={ref} animatewages={animatewages} className='Wages-Column-Right'>
        <div
          style={{
            width: '50%',
          }}
          className='Wage-Bar'
          ref={ref}
          animatewages={animatewages}
        >
          <div className='Wage-Bar-Title'>Minimum Wage</div>
          <div className='Wage-Data'>{currency.format(wageData.wages[1])}</div>
        </div>
        <div
          style={{
            width: '65%',
          }}
          className='Wage-Bar'
          ref={ref}
          animatewages={animatewages}
        >
          <div className='Wage-Bar-Title'>Hourly 10th Percentile Wage</div>
          <div className='Wage-Data'>{currency.format(wageData.wages[2])}</div>
        </div>
        <div
          style={{
            width: '70%',
          }}
          className='Wage-Bar'
          ref={ref}
          animatewages={animatewages}
        >
          <div className='Wage-Bar-Title'>Hourly 90th Percentile Wage</div>
          <div className='Wage-Data'>{currency.format(wageData.wages[3])}</div>
        </div>
        <div
          style={{
            width: '90%',
          }}
          className='Wage-Bar-Total'
          ref={ref}
          animatewages={animatewages}
        >
          <div className='Wage-Bar-Title-Blue'>Average Annual Wage</div>
          <div className='Wage-Data-Blue'>
            {currency.format(wageData.wages[5])}
          </div>
        </div>
        <div className='Updated-Date'>
          Wage data was retrieved June 18, 2021 from the 
          <a className={'Statistics-Link'} target="_blank" rel="noopener noreferrer" href='https://data.bls.gov/oes/#/geoOcc/Multiple%20occupations%20for%20one%20geographical%20area'>United States Bureau of Labor Statistics</a>
          for period May 2020.</div>
      </div>
    </div>
  );
}

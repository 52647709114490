import React, {useState, useEffect} from 'react';
import AnimateOnScroll from '../../../logic/AnimateOnScroll';
import './Overview.scss';
import SVGBullet from './SVGBullet';
/**
 *
 * @param {props.pathway} Pathway Data
 * @returns Overview Container
 */
export default function Overview(props) {
  const [animateoverview, setanimateoverview] = useState(0);
  const [ref, visible] = AnimateOnScroll({rootMargin: '-100px'});
  const rapidsCode = JSON.parse(props.pathway.rapidsCode);
  // const width = window.innerWidth;
  // const description = JSON.parse(props.pathway.description);
  // const firstSentence = description.split('. ');
  useEffect(() => {
    if (visible) {
      setanimateoverview(1);
    } else {
      setanimateoverview(0);
    }
  }, [visible]);
  return (
    <div
      ref={ref}
      animateoverview={animateoverview}
      className='Overview-Container'
    >
      <div className='Overview-Header-Container'>
        <div className='Overview-Title'>
          {props.pathway.occupation.length > 0
            ? JSON.parse(props.pathway.occupation)
            : 'Loading...'}
        </div>
        <div className='Overview-Sub-Title'>A Quick Overview</div>
        {/* <div className='Overview-Summary'>{description}</div> */}
      </div>
      <ul className='Overview-Timeline-Container'>
        <li className='Overview-Timeline-Node'>
          <div className='Overview-Node-Left'>
            <object className='Bullet'>
              <SVGBullet />
            </object>
          </div>
          <div className='Overview-Node-Right'>
            <div className='Timeline-Node-Header'>Estimated Program Length</div>
            <div className='Timeline-Node-Info'>
              {JSON.parse(props.pathway.programLength)}
            </div>
          </div>
        </li>
        {props.pathway.apprenticeshipType.length > 0 ? (
          <li className='Overview-Timeline-Node'>
            <div className='Overview-Node-Left'>
              <object className='Bullet'>
                <SVGBullet />
              </object>
            </div>
            <div className='Overview-Node-Right'>
              <div className='Timeline-Node-Header'>Apprenticeship Type</div>
              <div className='Timeline-Node-Info'>
                {JSON.parse(props.pathway.apprenticeshipType)}
              </div>
            </div>
          </li>
        ) : null}
        <li className='Overview-Timeline-Node'>
          <div className='Overview-Node-Left'>
            <object className='Bullet'>
              <SVGBullet />
            </object>
          </div>
          <div className='Overview-Node-Right'>
            <div className='Timeline-Node-Header'>
              {rapidsCode.length > 0 ? 'RAPIDS Code' : 'O*Net Code'}
            </div>
            <div className='Timeline-Node-Info'>
              {rapidsCode.length > 0
                ? rapidsCode
                : JSON.parse(props.pathway.oNetCode)}
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
}

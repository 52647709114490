import React from 'react';
import {Navigator} from '../../components';
import Card from '../../widgets/Carousel/Card';
import {HashLink} from 'react-router-hash-link';
import './CoursesPage.scss';
import SlideAnimation from '../../animations/SlideAnimation';
import ReactGA from 'react-ga4';
/**
 * @param {props.courseData} Online Courses Data
 * @returns The Online Courses Page
 */
export default function CoursesPage(props) {
  ReactGA.send({hitType: 'pageview', page: '/online-courses', title: 'Online Courses'});
  // Get Entry Course Data
  var entryCourses = props.courseData.filter(
    (pathway) => pathway.level === 'Entry'
  );
  entryCourses = entryCourses.filter((pathway) => {
    if (!JSON.parse(pathway.occupation)[4].includes('MSSC')) {
      return pathway;
    } else {
      return null;
    }
  });
  // Get Career Course Data
  var careerCourses = props.courseData.filter(
    (pathway) => pathway.level === 'Career'
  );
  // Get Advanced Course Data
  var advancedCourses = props.courseData.filter(
    (pathway) => pathway.level === 'Advanced'
  );
  // Get MSSC Course Data
  var msscCourses = props.courseData.filter((pathway) =>
    JSON.parse(pathway.occupation)[4].includes('MSSC')
  );
  return (
    <div className='Courses-Page-Container'>
      <Navigator backgroundColor={'white'} />
      <div className='Courses-Page-Hero-Container'>
        <SlideAnimation
          scrollTrigger
          xStart={-900}
          duration={0.8}
          node={'Courses-Page-Sub-Header'}
        >
          <div className='Courses-Page-Sub-Header'>Browse Our</div>
        </SlideAnimation>
        <SlideAnimation
          scrollTrigger
          xStart={-900}
          duration={1}
          node={'Courses-Page-Header'}
        ></SlideAnimation>
        <div className='Courses-Page-Header'>Online Courses</div>
        <SlideAnimation
          scrollTrigger
          xStart={-900}
          duration={1.2}
          node={'Courses-Hero-Button-Container'}
        >
          <div className='Courses-Hero-Button-Container'>
            <HashLink smooth to='#Entry' className='Courses-Hero-Button'>
            Entry
            </HashLink>
            <HashLink
              smooth
              to='#MSSC-Course-Packages'
              className='Courses-Hero-Button'
            >
            MSSC Course Packages
            </HashLink>
            <HashLink
              smooth
              to='#Career'
              className='Courses-Hero-Button'
            >
            Career
            </HashLink>
            <HashLink
              smooth
              to='#Advancement'
              className='Courses-Hero-Button'
            >
            Advancement
            </HashLink>
          </div>
        </SlideAnimation>
      </div>
      <div className='Courses-Page-Body'>
        <div id='Entry' className='Courses-Entry-Section'>
          <div className='Section-Header-Row'>
            <div className='Section-Break-Line' />
            <div className='Courses-Section-Header'>Entry</div>
            <div className='Section-Break-Line' />
          </div>
          <div className='Courses-Grid'>
            {entryCourses.map((course, index) => {
              const jsonCourse = JSON.parse(course.occupation);
              return <Card cardType='course' key={index} job={jsonCourse} />;
            })}
          </div>
        </div>
        <div id='MSSC-Course-Packages' className='Courses-MSSC-Section'>
          <div className='Section-Header-Row'>
            <div className='Section-Break-Line' />
            <div className='Courses-Section-Header'>MSSC Course Packages</div>
            <div className='Section-Break-Line' />
          </div>
          <div className='Courses-Grid'>
            {msscCourses.map((course, index) => {
              const jsonCourse = JSON.parse(course.occupation);
              return <Card cardType='course' key={index} job={jsonCourse} />;
            })}
          </div>
        </div>
        <div id='Career' className='Courses-Career-Section'>
          <div className='Section-Header-Row'>
            <div className='Section-Break-Line' />
            <div className='Courses-Section-Header'>Career</div>
            <div className='Section-Break-Line' />
          </div>
          <div className='Courses-Grid'>
            {careerCourses.map((course, index) => {
              const jsonCourse = JSON.parse(course.occupation);
              return <Card cardType='course' key={index} job={jsonCourse} />;
            })}
          </div>
        </div>
        <div id='Advancement' className='Courses-Advancement-Section'>
          <div className='Section-Header-Row'>
            <div className='Section-Break-Line' />
            <div className='Courses-Section-Header'>Advancement</div>
            <div className='Section-Break-Line' />
          </div>
          <div className='Courses-Advancement-Grid'>
            {advancedCourses.map((course, index) => {
              const jsonCourse = JSON.parse(course.occupation);
              return <Card cardType='course' key={index} job={jsonCourse} />;
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

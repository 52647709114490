import React, {useState, useEffect} from 'react';
import TimelineCard from './TimelineCard';
import AnimateOnScroll from '../../../logic/AnimateOnScroll';

function TimelineChart(props) {
  /**
   * TimelineChart Functional Component
   * * This functional component renders the TimelineChart to be displayed on desktop.
   * * It includes refs and a state that AnimateOnScroll uses to detect when it's elements are in view to animate.
   * * Conditionals are used to factor which type of chart should be displayed depending on the prop that's received from the page via timelineType: entry, career, or advancement.
   */
  const [animatetimeline, setAnimateTimeline] = useState(0);
  const [ref, visible] = AnimateOnScroll({rootMargin: '360px'});
  useEffect(() => {
    if (visible === true) {
      setAnimateTimeline(1);
      setTimeout(() => {
        setAnimateTimeline(0);
      }, 2000);
    }
    return () => {
      setAnimateTimeline(0);
    };
  }, [visible]);
  if (props.timelineType === 'entry') {
    return (
      <div className='Timeline-Charts'>
        <div className='Timeline-Chart-Container'>
          <div
            className='Reveal-Cards-Block'
            ref={ref}
            animatetimeline={animatetimeline}
          />
          <div className='Timeline-Card-Assembler'>
            <a id='linkUp' href='/Assembler'>
              <TimelineCard
                heading1='ENTRY'
                heading2='Assembler'
                className='Timeline-Card-Container-First-Green'
              />
            </a>
          </div>
          <div className='Line-1-2-Container'>
            <svg className='Line-1-SVG'>
              <path
                className='Line-Path-Green'
                d='
              M 0, 200
              Q 120, 180
                130, 70
              Q 140, 20
                250, 10
              L 320, 8
            '
              />
            </svg>
            <svg className='Line-2-SVG'>
              <path
                className='Line-Path-Green'
                d='
              M 0, 20
              L 390, 20
            '
              />
            </svg>
          </div>
          <div className='Career-Type-Card-Container'>
            <a id='linkUp' href='/Industrial-Engineering-Technician'>
              <TimelineCard
                heading1='CAREER'
                heading2='Industrial Engineering Technician'
                className='Timeline-Card-Container'
                animatetimeline={animatetimeline}
              />
            </a>
            <div className='Career-Type-Card-Single-Container'>
              <a id='linkUp' href='/Industrial-Maintenance-Mechanic'>
                <TimelineCard
                  heading1='CAREER'
                  heading2='Industrial Maintenance Mechanic'
                  className='Timeline-Card-Container'
                  animatetimeline={animatetimeline}
                />
              </a>
            </div>
          </div>
          <div className='Line-3-4-Container'>
            <svg className='Line-3-SVG'>
              <path
                className='Line-Path-Green'
                d='
              M 0, 40
              Q 200, 20
                210, 100
              Q 220, 170
                550, 160
              L 550, 190
            '
              />
            </svg>
            <svg className='Line-4-SVG'>
              <path
                className='Line-Path-Green'
                d='
               M 0, 110
               Q 200, 170
                 230, 100
               Q 250, 20
                 500, 20
               L 500, 20
            '
              />
            </svg>
          </div>
          <a id='linkUp' href='/Industrial-Engineer'>
            <TimelineCard
              heading1='ADVANCEMENT'
              heading2='Industrial Engineer'
              className='Timeline-Card-Container'
              animatetimeline={animatetimeline}
            />
          </a>
        </div>
        <div className='Timeline-Chart-Container'>
          <div
            className='Reveal-Cards-Block'
            ref={ref}
            animatetimeline={animatetimeline}
          />
          <a id='linkUp' href='/Picker/Packer'>
            <TimelineCard
              heading1='ENTRY'
              heading2='Picker/Packer'
              className='Timeline-Card-Container-First-Blue'
            />
          </a>
          <div className='Line-5-Container'>
            <svg className='Line-5-SVG'>
              <path
                className='Line-Path-Blue'
                d='
               M 0, 180
               L 500, 180
            '
              />
            </svg>
          </div>
          <div className='Timeline-Card-Quality-Contianer'>
            <a id='linkUp' href='Tool-and-Die-Maker'>
              <TimelineCard
                heading1='CAREER'
                heading2='Tool and Die Maker'
                className='Timeline-Card-Container'
                animatetimeline={animatetimeline}
              />
            </a>
          </div>
          <div className='Line-5-Container' style={{left: '50vw'}}>
            <svg className='Line-5-SVG'>
              <path
                className='Line-Path-Blue'
                d='
               M 0, 180
               L 500, 180
            '
              />
            </svg>
          </div>
          <div className='Timeline-Card-Quality-Contianer'>
            <a id='linkUp' href='/Production/Operation-Manager'>
              <TimelineCard
                heading1='ADVANCEMENT'
                heading2='Production/Operation Manager'
                className='Timeline-Card-Container'
                animatetimeline={animatetimeline}
              />
            </a>
          </div>
        </div>
      </div>
    );
  } else if (props.timelineType === 'career') {
    return (
      <div className='Timeline-Charts'>
        <div className='Timeline-Chart-Container'>
          <div
            className='Reveal-Cards-Block'
            ref={ref}
            animatetimeline={animatetimeline}
          />
          <div className='Timeline-Card-Assembler'>
            <a id='linkUp' href='/Material-Handler'>
              <TimelineCard
                heading1='ENTRY'
                heading2='Material Handler'
                className='Timeline-Card-Container-First-Green'
              />
            </a>
          </div>
          <div className='Line-1-2-Container'>
            <svg className='Line-1-SVG'>
              <path
                className='Line-Path-Green'
                d='
            M 0, 200
            Q 120, 180
              130, 70
            Q 140, 20
              250, 10
            L 320, 8
          '
              />
            </svg>
            <svg className='Line-2-SVG'>
              <path
                className='Line-Path-Green'
                d='
            M 0, 20
            L 390, 20
          '
              />
            </svg>
          </div>
          <div className='Career-Type-Card-Container'>
            <a id='linkUp' href='/CNC-Machine-Operator'>
              <TimelineCard
                heading1='CAREER'
                heading2='CNC Machine Operator'
                className='Timeline-Card-Container'
                animatetimeline={animatetimeline}
              />
            </a>
            <div className='Career-Type-Card-Single-Container'>
              <a id='linkUp' href='/Robotics-Technician'>
                <TimelineCard
                  heading1='CAREER'
                  heading2='Robotics Technician'
                  className='Timeline-Card-Container'
                  animatetimeline={animatetimeline}
                />
              </a>
            </div>
          </div>
          <div className='Line-3-4-Container'>
            <svg className='Line-3-SVG'>
              <path
                className='Line-Path-Green'
                d='
            M 0, 40
            Q 200, 20
              210, 100
            Q 220, 170
              550, 160
            L 550, 190
          '
              />
            </svg>
            <svg className='Line-4-SVG'>
              <path
                className='Line-Path-Green'
                d='
             M 0, 110
             Q 200, 170
               230, 100
             Q 250, 20
               500, 20
             L 500, 20
          '
              />
            </svg>
          </div>
          <a id='linkUp' href='/Frontline-Supervisor/Foreman'>
            <TimelineCard
              heading1='ADVANCEMENT'
              heading2='Frontline Supervisor/Foreman'
              className='Timeline-Card-Container'
              animatetimeline={animatetimeline}
            />
          </a>
        </div>
        <div className='Timeline-Chart-Container'>
          <div
            className='Reveal-Cards-Block'
            ref={ref}
            animatetimeline={animatetimeline}
          />
          <div className='Timeline-Card-Assembler'>
            <a id='linkUp' href='/Production-Helper'>
              <TimelineCard
                heading1='ENTRY'
                heading2='Production Helper'
                className='Timeline-Card-Container-First-Blue'
              />
            </a>
          </div>
          <div className='Line-1-2-Container' style={{top: '63vh'}}>
            <svg className='Line-1-SVG'>
              <path
                className='Line-Path-Blue'
                d='
            M 0, 200
            Q 120, 180
              130, 70
            Q 140, 20
              250, 10
            L 320, 8
          '
              />
            </svg>
            <svg className='Line-2-SVG'>
              <path
                className='Line-Path-Blue'
                d='
            M 0, 20
            L 390, 20
          '
              />
            </svg>
          </div>
          <div className='Career-Type-Card-Container'>
            <a id='linkUp' href='/Plastic-Process-Technician'>
              <TimelineCard
                heading1='CAREER'
                heading2='Plastic Process Technician'
                className='Timeline-Card-Container'
                animatetimeline={animatetimeline}
              />
            </a>
            <div className='Career-Type-Card-Single-Container'>
              <a id='linkUp' href='/CNC-Set-up-Programmer'>
                <TimelineCard
                  heading1='CAREER'
                  heading2='CNC Set-up Programmer'
                  className='Timeline-Card-Container'
                  animatetimeline={animatetimeline}
                />
              </a>
            </div>
          </div>
          <div className='Line-3-4-Container' style={{top: '54vh'}}>
            <svg className='Line-3-SVG'>
              <path
                className='Line-Path-Blue'
                d='
            M 0, 40
            Q 200, 20
              210, 100
            Q 220, 170
              550, 160
            L 550, 190
          '
              />
            </svg>
            <svg className='Line-4-SVG'>
              <path
                className='Line-Path-Blue'
                d='
             M 0, 110
             Q 200, 170
               230, 100
             Q 250, 20
               500, 20
             L 500, 20
          '
              />
            </svg>
          </div>
          <a id='linkUp' href='/Production/Operation-Manager'>
            <TimelineCard
              heading1='ADVANCEMENT'
              heading2='Production/Operation Manager'
              className='Timeline-Card-Container'
              animatetimeline={animatetimeline}
            />
          </a>
        </div>
      </div>
    );
  } else if (props.timelineType === 'advancement') {
    return (
      <div className='Timeline-Charts'>
        <div className='Timeline-Chart-Container'>
          <div
            className='Reveal-Cards-Block'
            ref={ref}
            animatetimeline={animatetimeline}
          />
          <div className='Timeline-Card-Assembler'>
            <a id='linkUp' href='/Material-Handler'>
              <TimelineCard
                heading1='ENTRY'
                heading2='Material Handler'
                className='Timeline-Card-Container-First-Green'
              />
            </a>
          </div>
          <div className='Line-1-2-Container'>
            <svg className='Line-1-SVG'>
              <path
                className='Line-Path-Green'
                d='
            M 0, 200
            Q 120, 180
              130, 70
            Q 140, 20
              250, 10
            L 320, 8
          '
              />
            </svg>
            <svg className='Line-2-SVG'>
              <path
                className='Line-Path-Green'
                d='
            M 0, 20
            L 390, 20
          '
              />
            </svg>
          </div>
          <div className='Career-Type-Card-Container'>
            <a id='linkUp' href='/Welder'>
              <TimelineCard
                heading1='CAREER'
                heading2='Welder'
                className='Timeline-Card-Container'
                animatetimeline={animatetimeline}
              />
            </a>
            <div className='Career-Type-Card-Single-Container'>
              <a id='linkUp' href='/Fabricator-Structural-Metal-Fabricators-and-Fitters'>
                <TimelineCard
                  heading1='CAREER'
                  heading2='Fabricator'
                  className='Timeline-Card-Container'
                  animatetimeline={animatetimeline}
                />
              </a>
            </div>
          </div>
          <div className='Line-3-4-Container'>
            <svg className='Line-3-SVG'>
              <path
                className='Line-Path-Green'
                d='
            M 0, 40
            Q 200, 20
              210, 100
            Q 220, 170
              550, 160
            L 550, 190
          '
              />
            </svg>
            <svg className='Line-4-SVG'>
              <path
                className='Line-Path-Green'
                d='
             M 0, 110
             Q 200, 170
               230, 100
             Q 250, 20
               500, 20
             L 500, 20
          '
              />
            </svg>
          </div>
          <a id='linkUp' href='/Frontline-Supervisor/Foreman'>
            <TimelineCard
              heading1='ADVANCEMENT'
              heading2='Frontline Supervisor/Foreman'
              className='Timeline-Card-Container'
              animatetimeline={animatetimeline}
            />
          </a>
        </div>
        <div className='Timeline-Chart-Container'>
          <div
            className='Reveal-Cards-Block'
            ref={ref}
            animatetimeline={animatetimeline}
          />
          <a id='linkUp' href='/Picker/Packer'>
            <TimelineCard
              heading1='ENTRY'
              heading2='Picker/Packer'
              className='Timeline-Card-Container-First-Blue'
            />
          </a>
          <div className='Line-5-Container'>
            <svg className='Line-5-SVG'>
              <path
                className='Line-Path-Blue'
                d='
               M 0, 180
               L 500, 180
            '
              />
            </svg>
          </div>
          <div className='Timeline-Card-Quality-Contianer'>
            <a id='linkUp' href='/Quality-Control-Inspector'>
              <TimelineCard
                heading1='CAREER'
                heading2='Quality Control Inspector'
                className='Timeline-Card-Container'
                animatetimeline={animatetimeline}
              />
            </a>
          </div>
          <div
            className='Line-1-2-Container'
            style={{top: '55vh', left: '51vw'}}
          >
            <svg className='Line-1-SVG'>
              <path
                className='Line-Path-Blue'
                d='
                M 0, 110
                Q 120, 180
                  130, 70
                Q 140, 20
                  250, 30
                L 550, 70
            '
              />
            </svg>
            <svg className='Line-2-SVG' style={{height: '50vw'}}>
              <path
                className='Line-Path-Blue'
                d='
                M 0, 0
                Q 200, 20
                  210, 90
                Q 220, 150
                  500, 40
                L 500, 40
            '
              />
            </svg>
          </div>
          <div className='Career-Type-Card-Container'>
            <a id='linkUp' href='/Quality-Technician'>
              <TimelineCard
                heading1='ADVANCEMENT'
                heading2='Quality Technician'
                className='Timeline-Card-Container'
                animatetimeline={animatetimeline}
              />
            </a>
            <div className='Career-Type-Card-Single-Container'>
              <a id='linkUp' href='/Industrial-Engineer'>
                <TimelineCard
                  heading1='ADVANCEMENT'
                  heading2='Industrial Engineer'
                  className='Timeline-Card-Container'
                  animatetimeline={animatetimeline}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (props.timelineType === 'ev-career'){
    return (
      <div className='Timeline-Charts'>
        <div className='Timeline-Chart-Container'>
          <div
            className='Reveal-Cards-Block'
            ref={ref}
            animatetimeline={animatetimeline}
          />
          <div className='Timeline-Card-Assembler'>
            <a id='linkUp' href='/Material-Handler'>
              <TimelineCard
                heading1='ENTRY'
                heading2='Material Handler'
                className='Timeline-Card-Container-First-Green'
              />
            </a>
          </div>
          <div className='Line-1-2-Container'>
            <svg className='Line-1-SVG'>
              <path
                className='Line-Path-Green'
                d='
            M 0, 200
            Q 120, 180
              130, 70
            Q 140, 20
              250, 10
            L 320, 8
          '
              />
            </svg>
            <svg className='Line-2-SVG'>
              <path
                className='Line-Path-Green'
                d='
            M 0, 20
            L 390, 20
          '
              />
            </svg>
          </div>
          <div className='Career-Type-Card-Container'>
            <a id='linkUp' href='/Electrical-and-Electronic-Engineering-Technician'>
              <TimelineCard
                heading1='CAREER'
                heading2='Electrical and Electronic Engineering Technician'
                className='Timeline-Card-Container'
                animatetimeline={animatetimeline}
              />
            </a>
            <div className='Career-Type-Card-Single-Container'>
              <a id='linkUp' href='/Energy-Storage-Technician'>
                <TimelineCard
                  heading1='CAREER'
                  heading2='Energy Storage Technician'
                  className='Timeline-Card-Container'
                  animatetimeline={animatetimeline}
                />
              </a>
            </div>
          </div>
          <div className='Line-3-4-Container'>
            <svg className='Line-3-SVG'>
              <path
                className='Line-Path-Green'
                d='
            M 0, 40
            Q 200, 20
              210, 100
            Q 220, 170
              550, 160
            L 550, 190
          '
              />
            </svg>
            <svg className='Line-4-SVG'>
              <path
                className='Line-Path-Green'
                d='
             M 0, 110
             Q 200, 170
               230, 100
             Q 250, 20
               500, 20
             L 500, 20
          '
              />
            </svg>
          </div>
          <a id='linkUp' href='/Frontline-Supervisor/Foreman'>
            <TimelineCard
              heading1='ADVANCEMENT'
              heading2='Frontline Supervisor/Foreman'
              className='Timeline-Card-Container'
              animatetimeline={animatetimeline}
            />
          </a>
        </div>
        <div className='Timeline-Chart-Container'>
          <div
            className='Reveal-Cards-Block'
            ref={ref}
            animatetimeline={animatetimeline}
          />
          <div className='Timeline-Card-Assembler'>
            <a id='linkUp' href='/Production-Helper'>
              <TimelineCard
                heading1='ENTRY'
                heading2='Production Helper'
                className='Timeline-Card-Container-First-Blue'
              />
            </a>
          </div>
          <div className='Line-1-2-Container' style={{top: '63vh'}}>
            <svg className='Line-1-SVG'>
              <path
                className='Line-Path-Blue'
                d='
            M 0, 200
            Q 120, 180
              130, 70
            Q 140, 20
              250, 10
            L 320, 8
          '
              />
            </svg>
            <svg className='Line-2-SVG'>
              <path
                className='Line-Path-Blue'
                d='
            M 0, 20
            L 390, 20
          '
              />
            </svg>
          </div>
          <div className='Career-Type-Card-Container'>
            <a id='linkUp' href='/Electromechanical-and-Mechatronics-Technician'>
              <TimelineCard
                heading1='CAREER'
                heading2='Electromechanical and Mechatronics Technician'
                className='Timeline-Card-Container'
                animatetimeline={animatetimeline}
              />
            </a>
            <div className='Career-Type-Card-Single-Container'>
              <a id='linkUp' href='/Electrical-and-Electronic-Equipment-Assembly-Technician'>
                <TimelineCard
                  heading1='CAREER'
                  heading2='Electrical and Electronic Equipment Assembly Technician'
                  className='Timeline-Card-Container'
                  animatetimeline={animatetimeline}
                />
              </a>
            </div>
          </div>
          <div className='Line-3-4-Container' style={{top: '54vh'}}>
            <svg className='Line-3-SVG'>
              <path
                className='Line-Path-Blue'
                d='
            M 0, 40
            Q 200, 20
              210, 100
            Q 220, 170
              550, 160
            L 550, 190
          '
              />
            </svg>
            <svg className='Line-4-SVG'>
              <path
                className='Line-Path-Blue'
                d='
             M 0, 110
             Q 200, 170
               230, 100
             Q 250, 20
               500, 20
             L 500, 20
          '
              />
            </svg>
          </div>
          <a id='linkUp' href='/Production/Operation-Manager'>
            <TimelineCard
              heading1='ADVANCEMENT'
              heading2='Production/Operation Manager'
              className='Timeline-Card-Container'
              animatetimeline={animatetimeline}
            />
          </a>
        </div>
      </div>
    );
  }
}

export default TimelineChart;

import React, {useState, useEffect} from 'react';
import * as Widgets from '../../widgets';
import './Navigator.scss';
import Logo from '../../assets/images/logo.png';
import AnimateOnScroll from '../../logic/AnimateOnScroll';
import MobileMenu from './MobileMenu.jsx';
import ReactGA from 'react-ga4';
/**
 * * Navigation System for whole app.
 * There is a one with a
 * white background and a normal background
 * depending on the page.
 * @param {props.backgroundColor} decides which kind of Navigator to Render
 * @returns Navigator based on props.backgroundColor
 */
export default function Navigator(props) {
  const [opennavbar, setopennavbar] = useState(0);
  const [ref, visible] = AnimateOnScroll({rootMargin: '50px'});
  useEffect(() => {
    if (visible) {
      setopennavbar(1);
    } else {
      setopennavbar(0);
    }
  }, [visible]);
  const linkStyle =
    props.backgroundColor === 'white'
      ? 'Navigator-Link-Black'
      : 'Navigator-Link';
  const dropdownStyle =
    props.backgroundColor === 'white'
      ? 'Navigator-Dropdown-Black'
      : 'Navigator-Dropdown';
  const navBarStyle =
    props.backgroundColor === 'white' ? 'Navigator-Bar-White' : 'Navigator-Bar';
  const onClickButton = (e) => {
    ReactGA.event({
      category: 'Navigation',
      action: 'Clicked Navigator Button',
      label: e.target.text,
    });
  };
  return (
    <nav
      className={
        props.backgroundColor === 'white' ? 'Navigator-White' : 'Navigator'
      }
    >
      <Widgets.AppLogo
        backgroundColor={props.backgroundColor}
        imageSrc={Logo}
      />
      <div ref={ref} opennavbar={opennavbar} className={navBarStyle}>
        <a onClick={onClickButton} className={linkStyle} href='/'>
          Home
        </a>
        <a className={linkStyle} href='/about'>
          About
        </a>
        <select
          onChange={(event) => {
            window.location.href = event.target.value;
          }}
          className={dropdownStyle}
          href='/'
        >
          <option onClick={onClickButton} value='/' className={'Dropdown-Option'}>
            Pathways
          </option>
          <option onClick={onClickButton} value='/entry' className={'Dropdown-Option'}>
            Entry
          </option>
          <option onClick={onClickButton} value='/career' className={'Dropdown-Option'}>
            Career
          </option>
          <option onClick={onClickButton} value='/ev-career' className={'Dropdown-Option'}>
            EV-Career
          </option>
          <option onClick={onClickButton} value='/advancement' className={'Dropdown-Option'}>
            Advancement
          </option>
        </select>
        <a onClick={onClickButton} className={linkStyle} href='/online-courses'>
          Online Courses
        </a>
        <a onClick={onClickButton} className={linkStyle} href='/contact'>
          Contact
        </a>
      </div>
      <Widgets.Button
        to={'https://www.imec.org'}
        buttonType={'link'}
        className={
          props.backgroundColor === 'white'
            ? 'IMEC-Button-Blue'
            : 'IMEC-Button'
        }
        text={'Return to IMEC'}
      />
      <MobileMenu />
    </nav>
  );
}

import React, {useEffect, useState} from 'react';
import AnimateOnScroll from '../../logic/AnimateOnScroll';
import {Button} from '../../widgets';
import './JumbotronPhoto.scss';
/**
 *
 * @param {props.sectionClassName} Class name of Section
 * @param {props.data} Component Data
 * @param {props.paragraph} Paragraph Data for the Component
 * @param {props.buttonDestination} Destination of the Button
 * @param {props.buttonText} Text of the Button
 * @returns Jumbotron Footer Component with Image and Button.
 */
export default function JumbotronPhoto(props) {
  const [slideinjumbo, setslideinjumbo] = useState(0);
  const [ref, visible] = AnimateOnScroll({rootMargin: '-50px'});
  useEffect(() => {
    if (visible) {
      setslideinjumbo(1);
    } else {
      setslideinjumbo(0);
    }
  }, [visible]);
  return (
    <section className={props.sectionClassName}>
      <div
        ref={ref}
        slideinjumbo={slideinjumbo}
        className='Jumbo-Paragraph-Container'
      >
        <div className='Jumbo-Header'>{props.data.header}</div>
        <p className='Jumbo-Paragraph'>{props.paragraph}</p>
        <Button
          to={props.buttonDestination}
          className={'Jumbo-Photo-Button'}
          text={props.buttonText}
        />
      </div>
    </section>
  );
}

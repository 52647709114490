import React from 'react';
import './Footer.scss';
import * as Widgets from '../../widgets';
import IMECLogo from '../../assets/images/IMEC_Logo.png';
import RADDLogo from '../../assets/images/RADD_Logo.png';
import FacebookIcon from '../../assets/images/svg/social/Social-Icons-01-white.svg';
import InstaIcon from '../../assets/images/svg/social/Social-Icons-03-white.svg';
import LinkedIcon from '../../assets/images/svg/social/Social-Icons-02-white.svg';
import TwitterIcon from '../../assets/images/svg/social/Social-Icons-04-white.svg';
/**
 *
 * @returns Footer for Application that
 * will always appear
 * even if the route returns nothing.
 */
export default function Footer() {
  const height = window.innerHeight;
  return (
    <div className='Footer-Container'>
      <Widgets.SVGTriangle
        svgClassName={'Footer-Triangle-SVG'}
        polygonPoints={`0 0, 100 ${height - height * 0.798}, 0 300`}
        polygonClassName={'Footer-Triangle'}
      />
      <div className='Footer-Column-Left'>
        <Widgets.SVGLine
          points={'0 0, 80 0'}
          svgClassName={'SVG-White-Footer'}
          lineClassName={'White-Footer-Line'}
        />
        <div className='Footer-Header'>Assemble The Future.</div>
        <a target="_blank" rel="noopener noreferrer" href={'https://www.kingdombranding.com/'} className='Kingdom-Branding-Icon-Container-Web'>
          Designed with &#9829; by Kingdom Branding
        </a>
      </div>
      <div className='Footer-Column-Center'>
        <div className='Social-Icons'>
          <a href={'https://www.facebook.com/IMECIllinois'} target="_blank" rel="noopener noreferrer">
            <img src={FacebookIcon} alt='social' className='Social-Icon' />
          </a>
          <img src={InstaIcon} alt='social' className='Social-Icon' />
          <a href={'https://www.linkedin.com/company/imec-illinois/'} target="_blank" rel="noopener noreferrer">
            <img src={LinkedIcon} alt='social' className='Social-Icon' />
          </a>
          <a href={'https://twitter.com/IMECillinois'} target="_blank" rel="noopener noreferrer">
            <img src={TwitterIcon} alt='social' className='Social-Icon' />
          </a>
        </div>
        <a target="_blank" rel="noopener noreferrer" href={'https://www.imec.org'}>
          <img src={IMECLogo} className={'Footer-Icon-Center'} alt='imec-logo' />
        </a>
      </div>
      <div className='Footer-Column-Right'>
        <Widgets.Button
          className={'Footer-Button'}
          text={'Entry'}
          to={'/entry'}
        />
        <Widgets.Button
          className={'Footer-Button'}
          text={'Career'}
          to={'career'}
        />
        <Widgets.Button
          className={'Footer-Button'}
          text={'Advancement'}
          to={'advancement'}
        />
        <a target="_blank" rel="noopener noreferrer" href={'https://raddtraining.com/'}>
          <img className={'Footer-Icon-Right'} src={RADDLogo}  alt='radd-logo' />
        </a>
        <a target="_blank" rel="noopener noreferrer" href={'https://www.kingdombranding.com/'}  className='Kingdom-Branding-Icon-Container-Mobile'>
          Designed with &#9829; by Kingdom Branding
        </a>
      </div>
    </div>
  );
}

import React, {useState, useEffect, useRef} from 'react';
import './AboutStory.scss';
import * as Widgets from '../../../widgets';
import AnimateOnScroll from '../../../logic/AnimateOnScroll';
import {isWindows} from 'react-device-detect';
import {gsap} from 'gsap';

function AboutStory(props) {
  /**
   * AboutStory functional component
   * *This functional component renders the About page's story and description of the company.
   * *Uses refs and state, utilizing the Animate on Scroll function, to initiate animations, with gsap, once element is in view.
   * *Utilizes the API data props passed to it to generate the text content.
   */
  
  const [ref, visible] = AnimateOnScroll({rootMargin: '-190px'});
  const [aboutStoryPFont, setAboutStoryPFont] = useState('Avenir Book');

  const aboutStoryParagraphContainer = useRef();
  const aboutStoryBadge = useRef();

  useEffect(() => {
    if (isWindows) {
      setAboutStoryPFont('Avenir Next');
    }
    if (visible === true) {
      animateAboutStory(true);
    }
    return () => {
      animateAboutStory(false);
    };
  }, [visible]);

  function animateAboutStory(el) {
    if(el === true){
      gsap.from(aboutStoryParagraphContainer.current, {opacity: '0', translateX: '-50vw', duration: 0.7});
      gsap.from(aboutStoryBadge.current, {translateX: '50vw', rotation: '+=360', duration: 0.9});
    } else if (el === false) {
      gsap.killTweensOf(aboutStoryBadge, aboutStoryParagraphContainer);
    }
  }

  return (
    <div
      className='About-Story-Container'
      ref={ref}
    >
      <div className='About-Story-Paragraph-Container' ref={aboutStoryParagraphContainer}>
        <p
          className='About-Story-Paragraph'
          style={{fontFamily: aboutStoryPFont}}
        >
          {props.aboutStoryText}
        </p>
      </div>
      <div className='About-Story-Badge-Container'>
        <div
          className='About-Story-Badge'
          ref={aboutStoryBadge}
        >
          <Widgets.Badge
            imgSrc={'/assets/png/greendiamond.png'}
            imgClassName={'About-Story-Badge-SVG'}
          />
        </div>
      </div>
    </div>
  );
}

export default AboutStory;

import React, {Component} from 'react';
import {Navigator} from '../../components/';
import './AdvancementPage.scss';
import * as ProgramComps from '../../components/Programs';
import JumbotronPhoto from '../../components/JumbotronPhoto';
import AdvanceLearnRightImage from '../../assets/images/webp/advancementlearnright.webp';
import LoadingPage from '../LoadingPage';
import ReactGA from 'react-ga4';
export default class AdvancementPage extends Component {
  /**
   * AdvancementPage class component.
   * *This class component renders the whole Advancement page of the app.
   * *This component was designed with program components, giving them props to justify the content and styling of the page.
   * *pathwayData is passed into this component through props which populates and maps the pathway cards portion.
   * *Page data received from props is broken down to get this page's specific data that is then distributed throughout it's imported components.
   * *Utilizes a simple if conditional based on if the page data is coming though, if not, displays loading screen.
   */
  render() {
    ReactGA.send({hitType: 'pageview', page: '/advancement', title: 'Advancement'});
    if(this.props.pageData[0] !== undefined){
      var filteredData = this.props.pageData.filter((page) => {
        return page.page === '"Advancement"';
      });
      var advPageData = filteredData[0];
      return (
        <div>
          <Navigator />
          <ProgramComps.ProgramHero
            pathwayTitle='Advancement'
            discoverHeading={JSON.parse(advPageData.paragraphs)[0][0]}
            entryTitleColor='#00A8E5'
            discoverBtnColor='#00A8E5'
            badgeOne={process.env.PUBLIC_URL + '/assets/png/bench.png'}
            programBadgeClass={'Program-Program-Badge-03'}
            programIndustryBadgeClass={'Program-Industry-Badge-03'}
            badgeTop='5vw'
            discoverDetails={JSON.parse(advPageData.paragraphs)[0][1]}
            link='#advanceCP'
          />
          <ProgramComps.ProgramPathways
            pathwayHeading1Size='1.4vw'
            pathwayHeading1='ADVANCEMENT PATHWAYS'
            heading2Color='#00A8E5'
            heading2Size='4vw'
            pathwayHeading2={JSON.parse(advPageData.paragraphs)[1][0]}
            pathText={JSON.parse(advPageData.paragraphs)[1][1]}
          />
          <ProgramComps.ProgramLearn
            leftContainerColor='#00A8E5'
            rightContainerImage={AdvanceLearnRightImage}
            learnHeading={JSON.parse(advPageData.paragraphs)[2][0]}
            learnText={JSON.parse(advPageData.paragraphs)[2][1]}
            listOp={true}
            listOp1={JSON.parse(advPageData.paragraphs)[2][2]}
            listOp2={JSON.parse(advPageData.paragraphs)[2][3]}
            listOp3={JSON.parse(advPageData.paragraphs)[2][4]}
            listOp4={JSON.parse(advPageData.paragraphs)[2][5]}
            learnContentHeight='25.5vw'
          />
          <ProgramComps.ProgramCareerPathways
            pathwayData={this.props.pathwayData}
            level={'Advanced'}
            id={'advanceCP'}
            careerPathwaysHeading={JSON.parse(advPageData.paragraphs)[3][0]}
            careerPathwaysHeadingColor='#00A8E5'
            pathwayDetails={JSON.parse(advPageData.paragraphs)[3][1]}
          />
          <ProgramComps.ProgramTimeline
            timelineLevel={'advancement'}
            timelineHeading1Color='#003B55'
            timelineHeading2Color='#00A8E5'
            timelineHeading2={JSON.parse(advPageData.paragraphs)[4][0]}
            timelineDetails={JSON.parse(advPageData.paragraphs)[4][1]}
          />
          <div className='Program-Bottom-Bar' />
          <JumbotronPhoto
            buttonDestination={'/'}
            buttonText={'SEE OTHER PATHWAYS'}
            data={{header: JSON.parse(advPageData.paragraphs)[5][0]}}
            paragraph={JSON.parse(advPageData.paragraphs)[5][1]}
            sectionClassName={'Jumbotron-Photo-5'}
          />
        </div>
      );
    } else if (this.props.pageData[0] === undefined){
      return <LoadingPage />;
    }
  }
}

import React, {useEffect, useState} from 'react';
import {gsap} from 'gsap';
import './Animations.scss';
import ScrollTrigger from 'gsap/ScrollTrigger';
export default function SlideAnimation(props) {
  const [animated, setAnimated] = useState(false);
  useEffect(() => {
    if (!animated) {
      gsap.registerPlugin(ScrollTrigger);
      if (props.scrollTrigger) {
        gsap.fromTo(`.${props.node}`, 
          {
            y: props.yStart ? props.yStart : 0,
            x: props.xStart ? props.xStart : 0
          },
          {
            scrollTrigger: {
              trigger: `.${props.node}`,
              start: '20px 80%',
              end: 'bottom 100px',
              toggleActions: 'restart restart none',
            },
            y: props.yEnd ? props.yEnd : 0,
            x: props.xEnd ? props.xEnd : 0,
            duration: props.duration ? props.duration : 1,
            delay: props.delay ? props.delay : 0,
            ease: 'slow'
          }
        );
        setAnimated(true);
      } else if (!props.scrollTrigger) {
        gsap.fromTo(`.${props.node}`, 
          {
            y: props.yStart ? props.yStart : 0,
            x: props.xStart ? props.xStart : 0
          },
          {
            y: props.yEnd ? props.yEnd : 0,
            x: props.xEnd ? props.xEnd : 0,
            duration: props.duration ? props.duration : 1,
            delay: props.delay ? props.delay : 0,
            ease: 'slow'
          }
        );
        setAnimated(true);
      }
    }
    return () => {
      gsap.killTweensOf(props.node);
    };
  }, [props.play]);
  return (
    <>
      {props.children}
    </>
  );
}

import React, {Component} from 'react';
import {Button} from '../../widgets';
import './ErrorPage.scss';
import ReactGA from 'react-ga4';
class ErrorPage extends Component {
  render() {
    ReactGA.send({hitType: 'pageview', page: '/error', title: 'Error'});
    return (
      <div className={'Error-Page-Container'}>
        <div className={'Error-Container'}>
          <div className='Error-Page-Text'>404 Error, Location Not Found</div>
          <Button text='Home' to='/' className={'Error-Page-Button'} />
        </div>
      </div>
    );
  }
}

export default ErrorPage;

import React, {useState, useEffect} from 'react';
import AnimateOnScroll from '../../logic/AnimateOnScroll';

export default function SVGLine(props) {
  const [animatesvgline, setanimatesvgline] = useState(0);
  const [ref, visible] = AnimateOnScroll({rootMargin: '-150px'});
  useEffect(() => {
    if (visible) {
      setanimatesvgline(1);
    } else {
      setanimatesvgline(0);
    }
  }, [visible]);
  return (
    <svg
      ref={ref}
      animatesvgline={animatesvgline}
      className={props.svgClassName}
    >
      <polyline points={props.points} className={props.lineClassName} />
    </svg>
  );
}

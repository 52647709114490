import React, {useState, useEffect, useRef} from 'react';
import './AboutHero.scss';
import AnimateOnScroll from '../../../logic/AnimateOnScroll';
import {isWindows} from 'react-device-detect';
import AboutHeroImageBackground from '../../../assets/images/webp/advancementherobackground.webp';
// import LoadingPage from '../../../pages/LoadingPage';
import {gsap} from 'gsap';

function AboutHero(props) {
  /**
   * About Hero Functional Component
   * *This functional component renders the top hero of the About page. There are refs and a general state tied to it's divs.
   * *This is to determine when a certain element should initiate animation, with gsap, based on when the element is in view.
   */
  const [ref, visible] = AnimateOnScroll({rootMargin: '10px'});
  const [aboutHeroPFont, setAboutHeroPFont] = useState('Avenir Book');
  const [cachingImages, setCachingImages] = useState(false);

  const aboutHeroTriangle = useRef();
  const aboutHeroImageRow = useRef();

  useEffect(() => {
    var aboutImages = [AboutHeroImageBackground];
    if(!cachingImages){
      setCachingImages(true);
      cacheImages(aboutImages);
    }
    return () => {
      setCachingImages(false);
    };
  }, []);
  useEffect(() => {
    if (isWindows) {
      setAboutHeroPFont('Avenir Next');
    }
    if (visible === true) {
      animateAboutHero(true);
    }
    return () => {
      animateAboutHero(false);
    };
  }, [visible]);

  function animateAboutHero(el) {
    if(el === true) {
      gsap.from(aboutHeroImageRow.current, {scaleX: 1, scaleY: 0.3, opacity: '0', transformOrigin: 'bottom', ease: 'linear', duration: '0.55'});
      gsap.from(aboutHeroTriangle.current, {x: '51vw', duration: '0.85'});
    } else if (el === false) {
      gsap.killTweensOf(aboutHeroImageRow, aboutHeroTriangle);
    }
  }

  const cacheImages = async (appImages) => {
    var promises = await appImages.map(async (image) => {
      return new Promise(function(resolve, reject) {
        const img = new Image();
        img.src = image;
        img.onload = () => {
          resolve();
        };
        img.onerror = () => {
          reject();
        };
      });
    });
    await Promise.all(promises).then(() => {
      setCachingImages(false);
    });
  };
  function AboutPageContent() {
    return (
      <div className='About-Hero-Container' ref={ref}>
        <div className='About-Hero-Background'>
          <div className='About-Hero-Text-Container'>
            <h1 className='About-Hero-Heading-1'>ABOUT</h1>
            <h1 className='About-Hero-Heading-2'>{props.aboutHeroH}</h1>
            <p className='About-Hero-Paragraph' style={{fontFamily: aboutHeroPFont}}>
              {props.aboutHeroP}
            </p>
          </div>
        </div>
        <div className='About-Hero-Image-Row' ref={aboutHeroImageRow}>
          <div
            className='About-Hero-Image'
            style={{backgroundImage: `url(${AboutHeroImageBackground}`}}
          />
          <div className='About-Hero-Triangle' ref={aboutHeroTriangle}/>
        </div>
        {/* <div className='About-Hero-Triangle-Row' ref={aboutHeroTriangleRow}>
          
        </div> */}
      </div>
    );
  }
  return <AboutPageContent />;
}
export default AboutHero;

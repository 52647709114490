const HomeData = {
  vision:
    'Manufacturers around the nation are experiencing a talent crisis. Long-term skilled employees are retiring from the workforce, and new applicants are unskilled. Additionally, innovative technologies are requiring continuing education for existing workers. IMEC believes building quality, customizable training programs and defining career pathways are the solution to these training challenges and the answer to developing the workforce of the future.',
  raise:
    'Sharpen your team’s skills by encouraging them to earn the credentials needed to fill the high-skill and in-demand manufacturing jobs in Illinois. The career pathways and training programs will prepare your workforce quickly and allow you to continue to advance their skills and overall value to your company.',
  entry: {
    paragraph:
      'Successful manufacturing careers begin with learning foundational skills and experiencing the scope of the industry. Many pathway options are available for entry-level workers of various ability levels, backgrounds, and interests. Entry positions allow potential careerists to assess fit and employers to assess employability skills for further investment and development.',
    professions: [
      {
        title: 'Assembler',
        image: 'assembler',
        summary:
          'Pariatur duis cupidatat ad laboris. Irure aute officia et sit laboris et mollit ad minim duis in reprehenderit eiusmod occaecat. Tempor aute fugiat elit id excepteur quis esse reprehenderit qui.',
      },
      {
        title: 'Picker/Packer',
        image: 'picker',
        summary:
          'Nisi ut consequat ad quis. Dolor quis et pariatur nostrud deserunt deserunt est. Dolor id fugiat tempor eu occaecat. Aliqua eu proident ea duis fugiat laboris dolore.',
      },
      {
        title: 'Material Handler',
        image: 'handler',
        summary:
          'Fugiat cupidatat ea ea non amet dolor eu fugiat voluptate eiusmod deserunt. Sint ad aliquip occaecat do velit aute dolor qui magna dolore. Ipsum mollit elit deserunt cupidatat non consectetur cillum.',
      },
      {
        title: 'Production Helper',
        image: 'engineer',
        summary: 'Ipsum laboris officia ea laboris esse pariatur cillum.',
      },
    ],
  },
  career: {
    paragraph:
      'Enrolling in an apprenticeship program is a worker’s first step in committing to a career pathway at your company. Apprenticeships provide paid positions to new employees along with custom, on-the-job training with seasoned professionals. Apprentices earn a nationally-recognized credential at the conclusion of the program.',
    professions: [
      {
        title: 'CNC Machine Operator',
        image: 'operator',
        summary:
          'Pariatur duis cupidatat ad laboris. Irure aute officia et sit laboris et mollit ad minim duis in reprehenderit eiusmod occaecat. Tempor aute fugiat elit id excepteur quis esse reprehenderit qui.',
      },
      {
        title: 'Plastic Process Technician',
        image: 'technician',
        summary:
          'Nisi ut consequat ad quis. Dolor quis et pariatur nostrud deserunt deserunt est. Dolor id fugiat tempor eu occaecat. Aliqua eu proident ea duis fugiat laboris dolore.',
      },
      {
        title: 'Welder',
        image: 'mechanic',
        summary:
          'Fugiat cupidatat ea ea non amet dolor eu fugiat voluptate eiusmod deserunt. Sint ad aliquip occaecat do velit aute dolor qui magna dolore. Ipsum mollit elit deserunt cupidatat non consectetur cillum.',
      },
      {
        title: 'Quality Control Inspector',
        image: 'engineer',
        summary: 'Ex velit id dolore ex ullamco mollit eu ullamco id.',
      },
    ],
  },
  advancement: {
    paragraph:
      'Motivate your workers with career advancement opportunities. By earning additional credentials or advanced degrees, developing leaders can qualify for elevated positions on your team. Building your bench secures your succession plan by preparing the next generation of leaders to be effective managers, supervisors, and more.',
    professions: [
      {
        title: 'Frontline Supervisor/Foreman',
        image: 'foreman',
        summary:
          'Pariatur duis cupidatat ad laboris. Irure aute officia et sit laboris et mollit ad minim duis in reprehenderit eiusmod occaecat. Tempor aute fugiat elit id excepteur quis esse reprehenderit qui.',
      },
      {
        title: 'Quality Technician',
        image: 'qualitytechnician',
        summary:
          'Nisi ut consequat ad quis. Dolor quis et pariatur nostrud deserunt deserunt est. Dolor id fugiat tempor eu occaecat. Aliqua eu proident ea duis fugiat laboris dolore.',
      },
      {
        title: 'Industrial Engineer',
        image: 'engineer',
        summary:
          'Fugiat cupidatat ea ea non amet dolor eu fugiat voluptate eiusmod deserunt. Sint ad aliquip occaecat do velit aute dolor qui magna dolore. Ipsum mollit elit deserunt cupidatat non consectetur cillum.',
      },
      {
        title: 'Production/Operation Manager',
        image: 'manager',
        summary:
          'Cillum anim nisi non velit non tempor irure ad adipisicing exercitation culpa.',
      },
    ],
  },
  findPathways: {
    header: 'Find The Pathway',
    paragraph:
      'Select a career pathway that is designed to train your team for the needs of Illinois’ in-demand manufacturing and technology sectors. These dynamic pathways will help them acquire the necessary academic and technical skills and transition them into joining the workforce.',
  },
};
export default HomeData;

import React from 'react';

const TimelineCard = (props) => {
  /**
   * TimelineCard Functional Component
   * *This functional component is rendered multiple times based on how many cards should be in a specific chart.
   * *Its props contain a specific className for different styling, as well as their headings, and a ref to know when it's in view.
   */
  return (
    <div
      className={props.className}
      ref={props.ref}
      animatetimeline={props.animatetimeline}
    >
      <h2 className='Timeline-Card-Heading1'>{props.heading1}</h2>
      <hr></hr>
      <h1 className='Timeline-Card-Heading2'>{props.heading2}</h1>
    </div>
  );
};

export default TimelineCard;

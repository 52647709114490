import React, {Component} from 'react';
import {Navigator} from '../../components/';
import './CareerPage.scss';
import * as ProgramComps from '../../components/Programs';
import JumbotronPhoto from '../../components/JumbotronPhoto';
import CareerLearnImage from '../../assets/images/webp/careerpagelearn.webp';
import LoadingPage from '../LoadingPage';
import ReactGA from 'react-ga4';
export default class CareerPage extends Component {
  /**
   * CareerPage class component.
   * *This class component renders the whole Career page of the app.
   * *This component was designed with program components, giving them props to justify the content and styling of the page.
   * *pathwayData is passed into this component through props which populates and maps the pathway cards portion.
   * *Page data received from props is broken down to get this page's specific data that is then distributed throughout it's imported components.
   * *Utilizes a simple if conditional based on if the page data is coming though, if not, displays loading screen.
   */
  render() {
    ReactGA.send({hitType: 'pageview', page: '/career', title: 'Career'});
    if(this.props.pageData[0] !== undefined){
      var filteredData = this.props.pageData.filter((page) => {
        return page.page === '"Career"';
      });
      var careerPageData = filteredData[0];
      return (
        <div>
          <Navigator />
          <ProgramComps.ProgramHero
            pathwayTitle='Career'
            discoverHeading={JSON.parse(careerPageData.paragraphs)[0][0]}
            entryTitleColor='#FFFFFF'
            badgeOne={process.env.PUBLIC_URL + '/assets/png/identifying.png'}
            discoverBtnColor='#FFFFFF'
            discoverDetails={JSON.parse(careerPageData.paragraphs)[0][1]}
            programBadgeClass={'Program-Program-Badge-02'}
            programIndustryBadgeClass={'Program-Industry-Badge-02'}
            badgeTop='4vw'
            link='#careerCP'
          />
          <ProgramComps.ProgramPathways
            pathwayHeading1={JSON.parse(careerPageData.paragraphs)[1][0]}
            pathwayHeading1Size='1.8vw'
            heading2Color='#003B55'
            heading2Size='4vw'
            pathwayHeading2='IDENTIFYING A CAREER PATHWAY'
            pathText={JSON.parse(careerPageData.paragraphs)[1][1]}
          />
          <ProgramComps.ProgramLearn
            leftContainerColor='#006885'
            rightContainerImage={CareerLearnImage}
            learnHeading={JSON.parse(careerPageData.paragraphs)[3][0]}
            learnText={JSON.parse(careerPageData.paragraphs)[3][1]}
            listOp='false'
            learnContentHeight='14.5vw'
            buttonText={JSON.parse(careerPageData.paragraphs)[3][2]}
            buttonDestination={
              'http://info.imec.org/get-your-free-work-process-schedule-1'
            }
          />
          <ProgramComps.ProgramCareerPathways
            pathwayData={this.props.pathwayData}
            level={'Career'}
            id={'careerCP'}
            careerPathwaysHeading={JSON.parse(careerPageData.paragraphs)[4][0]}
            careerPathwaysHeadingColor='#006885'
            pathwayDetails={JSON.parse(careerPageData.paragraphs)[4][1]}
          />
          <ProgramComps.ProgramTimeline
            timelineLevel='career'
            timelineHeading1Color='#006885'
            timelineHeading2Color='#003B55'
            timelineHeading2={JSON.parse(careerPageData.paragraphs)[5][0]}
            timelineDetails={JSON.parse(careerPageData.paragraphs)[5][1]}
          />
          <div className='Program-Bottom-Bar' />
          <JumbotronPhoto
            buttonDestination={'/advancement'}
            buttonText={'SEE OTHER PATHWAYS'}
            data={{header: JSON.parse(careerPageData.paragraphs)[6][0]}}
            paragraph={JSON.parse(careerPageData.paragraphs)[6][1]}
            sectionClassName={'Jumbotron-Photo-4'}
          />
        </div>
      );
    } else if(this.props.pageData[0] === undefined){
      return <LoadingPage />;
    }
  }
}
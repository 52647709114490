import React from 'react';
// Import React Google Analytics
import ReactGA from 'react-ga4';
export default function Card(props) {
  /**
   * * Card Function for Carousel and Online Courses Page.
   * This function renders a card dependent on the props.cardType
   //? props.cardType === 'course' are for the Online Courses Page
   //? props.cardType !== 'course' are for the Home Page Carousel
   */
  if (props.cardType !== 'course') {
    return (
      <a
        onClick={() => {
          ReactGA.event({
            category: 'Button',
            action: 'Clicked Card',
            label: `Pathway: ${props.job.title.replace(/\s/g, '-').replace(/[{()}]/g, '')}`,
          });
        }}
        href={`/${props.job.title.replace(/\s/g, '-').replace(/[{()}]/g, '')}`}
        className={
          props.level === 'career'
            ? 'Carousel-Career-Card'
            : props.level === 'advanced'
              ? 'Carousel-Advanced-Card'
              : 'Carousel-Card'
        }
      >
        <img
          alt={'hand'}
          className={'Card-Image'}
          src={`assets/png/${props.job.image
            .replace(/[{}]/g, '')
            .replace(/[()]/g, '')}.png`}
        />
        <div className='Card-Header'>{props.job.title.replace(/\([^()]*\)/g, '')}</div>
        {/* <div className='Card-Summary'>{props.job.summary}</div> */}
      </a>
    );
  } else if (props.cardType === 'course') {
    const courseName = props.job[4];
    const courseDetails = props.job[17];
    if (courseName !== '') {
      // For Getting Images from Google Drive
      // let imageId = props.job[12].split('/')[5];
      // let imageUrl = props.job[12].replace(imageId, '');
      // var imageHref = imageUrl.replace(
      //   'file/d//view?usp=sharing',
      //   `uc?export=view&id=${imageId}`
      // );
      if (props.job[13]) {
        return (
          <div>
            <div
              className='Courses-Card'
            >
              <img
                alt={'course'}
                className={'Course-Card-Image'}
                src={
                  `assets/jpg/course_images/${courseName
                    .replace(/[{}]/g, '')
                    .replace(/[()]/g, '')
                    .replace('Mfg', 'Manufacturing')
                  }.jpg`
                }
              />
              <div className='Course-Card-Header-Container'>
                <div className='Course-Card-Header'>
                  {courseName.replace(' Mfg', ' Manufacturing')}
                </div>
                <div className='Course-Card-Sub-Header'>{props.job[0]}</div>
                <div className='Course-Card-Stats'>
                  {props.job[6]} Classes | {props.job[7]} RTI Hours |{' '}
                  {props.job[11]}
                </div>
                <div className='Course-Card-Buttons' >
                  <a
                    onClick={() => {
                      ReactGA.event({
                        category: 'Button',
                        action: 'Clicked Card',
                        label: `Course: ${courseName
                          .replace(/[{}]/g, '')
                          .replace(/[()]/g, '')
                          .replace('Mfg', 'Manufacturing')}`,
                      });
                    }}
                    target='_blank'
                    rel='noopener noreferrer'
                    href={`https://${props.job[13]
                      .replace('view?usp=sharing', '')
                      .replace('https://', '')}`}
                    className={'Course-Card-Button'}
                  >
                  Register
                  </a>
                  {courseDetails ? (
                    <a
                      onClick={() => {
                        ReactGA.event({
                          category: 'Button',
                          action: 'Clicked Card Details',
                          label: `Course: ${courseName
                            .replace(/[{}]/g, '')
                            .replace(/[()]/g, '')
                            .replace('Mfg', 'Manufacturing')}`,
                        });
                      }}
                      href={`https://${courseDetails
                        .replace('view?usp=sharing', '')
                        .replace('https://', '')}`}
                      target='_blank'
                      rel='noopener noreferrer'
                      className={'Course-Card-Button'}
                    >
                    Details
                    </a>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        );
      }
    }
  }
}

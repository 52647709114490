import React, {useState} from 'react';
import ReactGA from 'react-ga4';
/**
 *
 * @returns Mobile Navigation Menu
 */
function MobileMenu() {
  const [hidden, setHidden] = useState(true);
  const onClickButton = (e) => {
    ReactGA.event({
      category: 'Navigation',
      action: 'Clicked Mobile Navigator Button',
      label: e.target.text,
    });
  };
  if (!hidden) {
    return (
      <div className='Mobile-Menu-Container-Open'>
        <div className='Hamburger-Button-Container'>
          <div
            onClick={(e) => {
              e.preventDefault();
              setHidden(!hidden);
            }}
            className='Close-Button'
          >
            <i style={{color: '#B2D233'}} className='fad fa-times fa-lg'></i>
          </div>
        </div>
        <div className='Mobile-Menu-List'>
          <a onClick={onClickButton} href='/' className='Mobile-Menu-Link'>
            Home
          </a>
          <a onClick={onClickButton} href='/about' className='Mobile-Menu-Link'>
            About
          </a>
          <a onClick={onClickButton} href='/online-courses' className='Mobile-Menu-Link'>
            Online Courses
          </a>
          <a onClick={onClickButton} href='/contact' className='Mobile-Menu-Link'>
            Contact
          </a>
          <a onClick={onClickButton} href='/entry' className='Mobile-Menu-Link'>
            Entry
          </a>
          <a onClick={onClickButton} href='/career' className='Mobile-Menu-Link'>
            Career
          </a>
          <a onClick={onClickButton} href='/ev-career' className='Mobile-Menu-Link'>
            EV-Career
          </a>
          <a onClick={onClickButton} href='/advancement' className='Mobile-Menu-Link'>
            Advancement
          </a>
          <a onClick={onClickButton} target="_blank" rel="noreferrer" href='https://www.imec.org' className='Mobile-Menu-Link-White'>
            Return To IMEC
          </a>
        </div>
      </div>
    );
  } else if (hidden) {
    return (
      <div className='Mobile-Menu-Container-Closed'>
        <div className='Hamburger-Button-Container'>
          <div
            onClick={(e) => {
              e.preventDefault();
              setHidden(!hidden);
            }}
            className='Hamburger-Button'
          >
            <i style={{color: '#B2D233'}} className='fad fa-bars fa-lg'></i>
          </div>
        </div>
      </div>
    );
  }
}

export default MobileMenu;

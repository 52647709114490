import React, {useState, useEffect, useRef} from 'react';
import './ProgramHero.scss';
import AnimateOnScroll from '../../../logic/AnimateOnScroll';
import {isFirefox, isChrome, isWindows} from 'react-device-detect';
import {HashLink} from 'react-router-hash-link';
import EntryBackground from '../../../assets/images/entrypagehero.png';
import EntryTriangle from '../../../assets/images/webp/skitter.webp';
import CareerBackground from '../../../assets/images/webp/careerpageherobackground.webp';
import EVCareerBackground from '../../../assets/images/webp/evCareerHero.png';
import CareerTriangle from '../../../assets/images/webp/careertriangle.webp';
import AdvanceTriangle from '../../../assets/images/webp/advancementcogs.webp';
import AdvanceHeroBackground from '../../../assets/images/webp/advancementherobackground.webp';
import EntryBadge from '../../../assets/images/svg/Vector Assets-01.svg';
import CareerBadge from '../../../assets/images/svg/Vector Assets-04.svg';
import BlueDiamondBadge from '../../../assets/images/svg/Vector Assets-18.svg';
import {gsap} from 'gsap';

export default function ProgramHero(props) {
  /**
   * ProgramHero Functional Component
   * *This functional component attached to the Entry, Career, and Advancement pages renders all of these pages' top heros.
   * *The component is given data through props to populate it's specific content and to style some tricky areas.
   * *Features refs and a state that utilizes Animate on Scroll to initiate animations with gsap when elements are in view.
   * *The component also utilizes a package, 'react-device-detect', to determine which browser it is rendering on. This is to determine some styling based on the browser.
   * *This component utilizes the 'react-router-hash-link' package as well to smoothly generate a link from this top component to the ProgramCareerPathways component.
   */
  const [ref, visible] = AnimateOnScroll({rootMargin: '-100px'});
  const [isMozilla, setIsMozilla] = useState(Boolean);
  const [discoverFont, setDiscoverFont] = useState('Avenir Book');
  const [cachingImages, setCachingImages] = useState(false);

  const progTitle = useRef();
  const progButton = useRef();
  const progParagraph = useRef();
  const stickerContainer = useRef();

  useEffect(() => {
    var progHeroImages = [EntryBackground, EntryTriangle, CareerBackground, CareerTriangle, AdvanceHeroBackground, AdvanceTriangle, EntryBadge, CareerBadge, BlueDiamondBadge, EVCareerBackground];
    if(!cachingImages){
      setCachingImages(true);
      cacheImages(progHeroImages);
    }
    return () => {
      setCachingImages(false);
    };
  }, []);

  useEffect(() => {
    if (isFirefox) {
      setIsMozilla(true);
    } else if (isChrome) {
      setIsMozilla(false);
    }
    if (isWindows) {
      setDiscoverFont('Avenir Next');
    }
    return () => {
      setIsMozilla(Boolean);
      setDiscoverFont('Avenir Book');
    };
  }, []);

  useEffect(() => {
    if (visible === true) {
      animateHero(true, window.innerWidth);
    }
    return () => {
      animateHero(false);
    };
  },[visible],[]);
  
  function animateHero(el, screenWidth){
    if(screenWidth > 600) {
      if(el === true){
        gsap.from(progTitle.current, {x: '-300px', duration: '1'});
        gsap.from(progParagraph.current, {x: '6vw', duration: '1'});
        gsap.from(progButton.current, {x: '-10vw', opacity: '0', duration: '1'});
        gsap.from(stickerContainer.current, {x: '8vw', duration: '1'});
      } else if(el === false) {
        gsap.killTweensOf(progTitle, progParagraph, progButton, stickerContainer);
      }
    }
  }

  const cacheImages = async (appImages) => {
    var promises = await appImages.map(async (image) => {
      return new Promise(function(resolve, reject) {
        const img = new Image();
        img.src = image;
        img.onload = () => {
          resolve();
        };
        img.onerror = () => {
          reject();
        };
      });
    });
    await Promise.all(promises).then(() => {
      setCachingImages(false);
    });
  };
  const CheckTitle = (e) => {
    if (e === 'background') {
      if (props.pathwayTitle === 'Entry'){
        return EntryBackground;
      } else if (props.pathwayTitle === 'Career'){
        return CareerBackground;
      } else if (props.pathwayTitle === 'Advancement'){
        return AdvanceHeroBackground;
      } else if (props.pathwayTitle === 'EV-Career'){
        return EVCareerBackground;
      }
    } else if (e === 'triangle') {
      if (props.pathwayTitle === 'Entry'){
        return EntryTriangle;
      } else if (props.pathwayTitle === 'Career' || props.pathwayTitle === 'EV-Career'){
        return CareerTriangle;
      } else if (props.pathwayTitle === 'Advancement'){
        return AdvanceTriangle;
      }
    } 
  };
  const CheckBadge = () => {
    if(props.pathwayTitle === 'Entry'){
      return EntryBadge;
    } else if(props.pathwayTitle === 'Career'){
      return CareerBadge;
    } else if (props.pathwayTitle === 'Advancement'){
      return BlueDiamondBadge;
    } else if (props.pathwayTitle === 'EV-Career'){
      return process.env.PUBLIC_URL + '/assets/png/evbadgenew.png';
    }
  };
  return (
    <div
      className={'Program-Hero-Background'}
      style={{
        backgroundImage: `url(${CheckTitle('background')})`,
      }}
    >
      <div className={'Program-Hero-Container'} ref={ref}>
        <div
          className='Program-Badge-Container'
          ref={stickerContainer}
          style={{top: props.badgeTop}}
        >
          <img
            src={props.badgeOne}
            className={props.programIndustryBadgeClass}
          />
          {props.pathwayTitle === 'EV-Career' ?
            <HashLink
              to={props.link}
              ref={progButton}
              className={'Program-Badge-EV-Container'}
            >
              <img
                src={CheckBadge()}
                className={props.programBadgeClass}
              />
            </HashLink> :
            <img
              src={CheckBadge()}
              className={props.programBadgeClass}
            />
          }
        </div>
        <div
          className='Transparent-Triangle'
          style={{backgroundImage: `url(${CheckTitle('triangle')})`}}
        />
        <div
          className={
            props.pathwayTitle === 'Career'
              ? 'Career-Title-Container'
              : props.pathwayTitle === 'Advancement'
                ? 'Advancement-Title-Container'
                : props.pathwayTitle === 'EV-Career' ?
                  'Career-Title-Container'
                  : 'Entry-Title-Container'
          }
          ref={progTitle}
        >
          <svg
            className={
              isMozilla === false
                ? 'Program-Line-Zone-Chrome'
                : 'Program-Line-Zone-Firefox'
            }
          >
            <polyline points='50 80 260 80' className='Program-Line' />
          </svg>
          <h1
            className={`${props.pathwayTitle}-Hero-Title`}
            style={{
              color: props.entryTitleColor,
            }}
          >
            {props.pathwayTitle}
          </h1>
        </div>
        <div className='Discover-Container'>
          <div
            className='Discover-Content-Container'
            ref={progParagraph}
          >
            <h3 className='Discover-Title'>{props.discoverHeading}</h3>
            <p className='Discover-Paragraph' style={{fontFamily: discoverFont}}>
              {props.discoverDetails}
            </p>
            <HashLink
              to={props.link}
              className='SB-Button'
              ref={progButton}
              style={{color: props.discoverBtnColor}}
            >
              START BROWSING ➝
            </HashLink>
          </div>
        </div>
      </div>
    </div>
  );
}


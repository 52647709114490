import React, {Component} from 'react';
import {Navigator} from '../../components/';
import './EntryPage.scss';
import * as ProgramComps from '../../components/Programs';
import JumbotronPhoto from '../../components/JumbotronPhoto';
import EntryTriangle from '../../assets/images/webp/skitter.webp';
import LoadingPage from '../LoadingPage';
import ReactGA from 'react-ga4';
export default class EntryPage extends Component {
  /**
   * EntryPage class component.
   * *This class component renders the whole Entry page of the app.
   * *This component was designed with program components, giving them props to justify the content and styling of the page.
   * *pathwayData is passed into this component through props which populates and maps the pathway cards portion.
   * *Page data received from props is broken down to get this page's specific data that is then distributed throughout it's imported components.
   * *Utilizes a simple if conditional based on if the page data is coming though, if not, displays loading screen.
   */
  render() {
    ReactGA.send({hitType: 'pageview', page: '/entry', title: 'Entry'});
    if(this.props.pageData[0] !== undefined){
      var filteredData = this.props.pageData.filter((page) => {
        return page.page === '"Entry"';
      });
      var entryPageData = filteredData[0];
      return (
        <div>
          <Navigator />
          <ProgramComps.ProgramHero
            pathwayTitle='Entry'
            discoverHeading={
              JSON.parse(entryPageData.paragraphs)[0][0]
            }
            entryTitleColor='#B2D233'
            badgeOne={process.env.PUBLIC_URL + '/assets/png/industry.png'}
            discoverBtnColor='#B2D233'
            discoverDetails={JSON.parse(entryPageData.paragraphs)[0][1]}
            programBadgeClass={'Program-Program-Badge-01'}
            programIndustryBadgeClass={'Program-Industry-Badge-01'}
            badgeTop='2.5vw'
            link='#entryCP'
          />
          <ProgramComps.ProgramPathways
            pathwayHeading1='ENTRY PATHWAYS'
            pathwayHeading1Size='1.8vw'
            heading2Color='linear-gradient(161deg, #70B700 0%, #6CAC09 100%)'
            heading2Size='4vw'
            pathwayHeading2={JSON.parse(entryPageData.paragraphs)[1][0]}
            pathText={JSON.parse(entryPageData.paragraphs)[1][1]}
          />
          <ProgramComps.ProgramLearn
            leftContainerColor='linear-gradient(161deg, #70B700 0%, #6CAC09 100%)'
            rightContainerImage={EntryTriangle}
            learnHeading={JSON.parse(entryPageData.paragraphs)[2][0]}
            learnText={JSON.parse(entryPageData.paragraphs)[2][1]}
            listOp={true}
            listOp1={JSON.parse(entryPageData.paragraphs)[2][2]}
            listOp2={JSON.parse(entryPageData.paragraphs)[2][3]}
            listOp3={JSON.parse(entryPageData.paragraphs)[2][4]}
            listOp4={JSON.parse(entryPageData.paragraphs)[2][5]}
            learnContentHeight='23vw'
          />
          <ProgramComps.ProgramCareerPathways
            pathwayData={this.props.pathwayData}
            id={'entryCP'}
            level={'Entry'}
            careerPathwaysHeading={JSON.parse(entryPageData.paragraphs)[3][0]}
            careerPathwaysHeadingColor='#90D12A'
            pathwayDetails={JSON.parse(entryPageData.paragraphs)[3][1]}
          />
          <ProgramComps.ProgramTimeline
            timelineLevel='entry'
            timelineHeading1Color='#90D12A'
            timelineHeading2Color='#003B55'
            timelineHeading2={JSON.parse(entryPageData.paragraphs)[4][0]}
            timelineDetails={JSON.parse(entryPageData.paragraphs)[4][1]}
          />
          <div className='Program-Bottom-Bar' />
          <JumbotronPhoto
            buttonDestination={'/career'}
            buttonText={'SEE OTHER PATHWAYS'}
            data={{header: JSON.parse(entryPageData.paragraphs)[5][0]}}
            paragraph={JSON.parse(entryPageData.paragraphs)[5][1]}
            sectionClassName={'Jumbotron-Photo-1'}
          />
        </div>
      );
    } else if (this.props.pageData[0] === undefined){
      return <LoadingPage />;
    }
  }
}

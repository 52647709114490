import React, {useEffect, useState} from 'react';
import {gsap} from 'gsap';
import './Animations.scss';
import ScrollTrigger from 'gsap/ScrollTrigger';
export default function RotateAnimation(props) {
  const [animated, setAnimated] = useState(false);
  useEffect(() => {
    if (props.scrollTrigger) {
      if (!animated) {
        gsap.registerPlugin(ScrollTrigger);
        gsap.fromTo(`.${props.node}`,
          {
            rotation: props.rotationStart ? props.rotationStart : '-80deg',
          },  
          {
            scrollTrigger: {
              start:
              `${props.triggerStart ?
                props.triggerStart : '20px 80%'}`,
              trigger: `.${props.node}`,
              end: 'center center',
              toggleActions: 'restart restart none',
              scrub: props.scrub ? props.scrub : false,
            },
            rotation: props.rotationEnd ? props.rotationEnd : 0,
            duration: props.duration ? props.duration : 1,
            delay: props.delay ? props.delay : 0,
            ease: 'slow'
          },
        );
      }
    } else if (!props.scrollTrigger) {
      if (!animated) {
        gsap.registerPlugin(ScrollTrigger);
        gsap.fromTo(`.${props.node}`,
          {
            rotation: props.rotationStart ? props.rotationStart : '-80deg',
          },  
          {
            rotation: props.rotationEnd ? props.rotationEnd : 0,
            duration: props.duration ? props.duration : 1,
            delay: props.delay ? props.delay : 0,
            ease: 'slow'
          },
        );
      }
    }
    return () => {
      gsap.killTweensOf(`.${props.node}`);
      setAnimated(false);
    };
  }, []);
  return (
    <>
      {props.children}
    </>
  );
}

import React, {useState} from 'react';
import './AccordionBoard.scss';
import {v4 as uuid} from 'uuid';
/**
 *
 * @param {props.title} Shows the Title of the Shelf
 * @param {props.count} Shows the Competency Count
 * @returns Shelf for Accordion Board
 */
export default function Shelf(props) {
  const [isOpen, setIsOpen] = useState(false);
  const details = props.details;
  if (!isOpen) {
    return (
      <div className={'Accordion-Shelf-Container-Closed'}>
        <div
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          className='Accordion-Shelf-Top'
        >
          <div className={'Shelf-Title'}>{props.title}</div>
          <div className={'Shelf-Count'}>{props.count} Competencies</div>
          <div className={'Shelf-Button'}>+</div>
        </div>
      </div>
    );
  } else if (isOpen) {
    return (
      <div className={'Accordion-Shelf-Container-Open'}>
        <div
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          className='Accordion-Shelf-Top'
        >
          <div className={'Shelf-Title'}>{props.title}</div>
          <div className={'Shelf-Count'}>{props.count} Competencies</div>
          <div
            className={'Shelf-Button'}
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            -
          </div>
        </div>
        <ul className='Shelf-Details'>
          {details.map((detail) => {
            return <li key={uuid}>{detail}</li>;
          })}
        </ul>
      </div>
    );
  }
}

import React, {useState, useEffect} from 'react';
import './AboutHelp.scss';
import {isWindows} from 'react-device-detect';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import {gsap} from 'gsap';

function AboutHelp(props) {
  /**
   * About Help Function Component
   * *This functional component is the Help section of the about page using a ref for certain
   * *This component now uses gsap to animate its content with a scrollTrigger.
   */

  const [helpTextFont, setHelpTextFont] = useState('Avenir Book');
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    if(window.innerWidth > 500){
      gsap.timeline({
        scrollTrigger: {
          trigger: '#mainHelpContainer',
          start: 'bottom bottom+=300',
          end: 'center center-=240',
          scrub: true,
        }
      }).fromTo('#drapeLeft',
        {
          translateX: '50vw'
        },
        {
          translateX: '0vw'
        }, 'start').fromTo('#drapeRight',
        {
          translateX: '-50vw'
        },
        {
          translateX: '0vw'
        }, 'start').fromTo('#middleBar',
        {
          height: '0px'
        }, 
        {
          height: '300px'
        }, 'start');
      return () => {
        gsap.killTweensOf('#drapeLeft', '#drapeRight', '#middlebar');
      };
    } else if(window.innerWidth < 500) {
      gsap.timeline({
        scrollTrigger: {
          trigger: '#mainHelpContainer',
          start: 'bottom bottom+=300',
          end: 'center center-=240',
          scrub: true,
        }
      }).fromTo('#middleBar',
        {
          width: '0',
        }, 
        {
          width: '80%',
        }, 'start');
      return () => {
        gsap.killTweensOf('#middlebar');
      };
    }
  });

  useEffect(() => {
    if (isWindows) {
      setHelpTextFont('Avenir Next');
    }
  }, []);
  return (
    <div className='About-Help-Container' id='mainHelpContainer'>
      <div className='About-Help-Content-Row'>
        <div className='About-Drape-Left' id='drapeLeft' />
        <div className='About-Drape-Right' id='drapeRight' />
        <div className='Help-Content-Left-Container'>
          <h1 className='Help-Heading'>{props.helpHeading}</h1>
          <p className='Help-Heading-Text' style={{fontFamily: helpTextFont}}>
            {props.helpTextLeft}
          </p>
        </div>
        <div className='Help-Middle-Bar' id='middleBar'></div>
        <div className='Help-Content-Right-Container'>
          <ul className='Help-Right-Text' style={{fontFamily: helpTextFont}}>
            <li>{props.listItemOne}</li>
            <li>{props.listItemTwo}</li>
            <li>{props.listItemThree}</li>
            <li>{props.listItemFour}</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default AboutHelp;

import React, {Component} from 'react';
import {Navigator} from '../../components/';
import './HomePage.scss';
// Main Components
import {JumbotronPhoto} from '../../components';
// Home Components
import * as HomeComponents from '../../components/Home';
import HomeData from '../../assets/data/HomeData';
// Images
import welder from '../../assets/images/webp/welder.webp';
import raiseBar from '../../assets/images/webp/online-courses.webp';
// SVG
import entry from '../../assets/images/svg/Vector Assets-01.svg';
import greenDiamond from '../../assets/images/svg/Vector Assets-17.svg';
import blueDiamond from '../../assets/images/svg/Vector Assets-18.svg';
import greenArrow from '../../assets/images/svg/Vector Assets-20.svg';
import * as Widgets from '../../widgets';
import pointingHand from '../../assets/images/svg/Vector Assets-05.svg';
import ReactGA from 'react-ga4';
/**
 * * Home Page
 * @param {this.props.data} Occupation Data
 * @param {this.props.pageData} Home Page Data
 */
export class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data,
    };
  }
  render() {
    ReactGA.send({hitType: 'pageview', page: '/', title: 'Home'});
    var filteredData = this.props.pageData.filter((page) => {
      return page.page === '"Home"';
    });
    var homePageData = filteredData[0];
    var careerOccupations = this.props.pathwayData.filter(
      (occ) => JSON.parse(occ.level) === 'Career'
    );
    var greenOccupations = this.props.pathwayData.filter(
      (occ) => JSON.parse(occ.level) === 'Green Career'
    );
    const height = window.innerHeight;
    return (
      <div className={'Home-Container'}>
        <Navigator />
        <HomeComponents.HomeHero />
        <Widgets.SVGTriangle
          svgClassName={'Home-Hero-SVG'}
          polygonClassName={'Home-Hero-Triangle'}
          polygonPoints={`0 ${height - height * 0.753}, 140 10, 140 ${
            height - height * 0.485
          }`}
        />
        <HomeComponents.Paragraph
          headerClassName={'Paragraph-Header'}
          containerClassName={'Paragraph-Container'}
          leftContainerClassName={'Paragraph-Left'}
          rightContainerClassName={'Paragraph-Right'}
          paragraphClassName={'Paragraph-Paragraph'}
          header={'Behind The Vision'}
          paragraph={
            homePageData ? JSON.parse(homePageData.paragraphs)[0][1] : 'Loading'
          }
          buttonText={'Learn More'}
          buttonClassName={'Learn-More-Button-White'}
          buttonDestination={'/about'}
          imgClassName={'Paragraph-Image'}
          imgSrc={welder}
          imgAlt={'Vision'}
        />
        <Widgets.SVGLine
          points={`0 5, 400 5, 400 ${height * 1.34}, 1550 ${height * 1.34}`}
          svgClassName={'SVG-Time-Line'}
          lineClassName={'Time-Line'}
        />
        <div className={'Entry-Body-Container'}>
          <Widgets.SVGLine
            points={'0 50, 100 50, 200 50'}
            svgClassName={'SVG-Entry-Green'}
            lineClassName={'Green-Line'}
          />
          <Widgets.Badge
            scrub
            scrollTrigger
            containerClassName={'Breaking-Industry-Badge'}
            imgClassName={'Breaking-Industry-Image'}
            imgSrc={'/assets/png/industry.png'}
          />
          <Widgets.Badge
            scrub
            scrollTrigger
            containerClassName={'Entry-Badge'}
            imgClassName={'Entry-Badge-Image'}
            imgSrc={entry}
          />
          <HomeComponents.BodySection
            alignment={'right'}
            sectionClassName={'Section-1'}
            headerClassName={'Section-1-Header'}
            carouselHeader={'Pathway Starting Points'}
            header={'Entry'}
            sectionData={HomeData.entry}
            paragraph={
              homePageData
                ? JSON.parse(homePageData.paragraphs)[1][1]
                : 'Loading'
            }
            data={this.props.data}
            buttonText={'Learn More'}
            buttonClassName={'Learn-More-Button-Blue'}
          />
        </div>
        <div className={'Career-Body-Container'}>
          <Widgets.SVGLine
            points={'100 50, 100 80'}
            svgClassName={'SVG-Career-Blue'}
            lineClassName={'Dark-Blue-Line'}
          />
          <Widgets.Badge
            scrub
            scrollTrigger
            containerClassName={'Career-Badge'}
            imgClassName={'Career-Badge-Image'}
            imgSrc={'/assets/png/career.png'}
          />
          <Widgets.Badge
            scrub
            scrollTrigger
            containerClassName={'Identifying-Badge'}
            imgClassName={'Identifying-Badge-Image'}
            imgSrc={'/assets/png/identifying.png'}
          />
          <Widgets.Badge
            scrub
            scrollTrigger
            containerClassName={'Green-Diamond-Badge'}
            imgClassName={'Green-Diamond-Badge-Image'}
            imgSrc={greenDiamond}
          />
          <div id='EV-Careers'
            className='Green-Highlight-Container'>
            <div
              className='Green-Highlight'>
              <div className='Green-Header'>
                {homePageData ? JSON.parse(homePageData.paragraphs)[7][0] : 'Loading...'}
              </div>
              <div className="Green-Paragraph">
                {homePageData ? JSON.parse(homePageData.paragraphs)[7][1] : 'Loading...'}
              </div>
            </div>
            <img className='Green-Hand' src={pointingHand} />
          </div>
          <HomeComponents.BodySection
            alignment={'right'}
            sectionClassName={'Section-2'}
            headerClassName={'Section-2-Header'}
            header={'Career'}
            carouselHeader={'Next Steps'}
            levelOccupations={careerOccupations}
            greenOccupations={greenOccupations}
            sectionData={HomeData.career}
            paragraph={
              homePageData
                ? JSON.parse(homePageData.paragraphs)[2][1]
                : 'Loading'
            }
            data={this.props.data}
            buttonText={'Learn More'}
            buttonClassName={'Learn-More-Button-Green'}
          />
        </div>
        <div className={'Advanced-Body-Container'}>
          <Widgets.SVGLine
            points={'0 5,500 5, 1700 5'}
            lineClassName={'Full-Horizontal'}
            svgClassName={'SVG-Full-Horizontal'}
          />
          <Widgets.SVGLine
            points={'50 0, 50 100, 50 150'}
            svgClassName={'SVG-Advancement-Blue'}
            lineClassName={'Light-Blue-Line'}
          />
          <Widgets.Badge
            scrub
            scrollTrigger
            containerClassName={'Blue-Diamond-Badge'}
            imgClassName={'Blue-Diamond-Image'}
            imgSrc={blueDiamond}
          />
          <Widgets.Badge
            scrub
            scrollTrigger
            containerClassName={'Bench-Badge'}
            imgClassName={'Bench-Image'}
            imgSrc={'/assets/png/bench.png'}
          />
          <Widgets.Badge
            scrub
            scrollTrigger
            containerClassName={'Green-Arrow-Badge'}
            imgClassName={'Green-Arrow-Image'}
            imgSrc={greenArrow}
          />
          <HomeComponents.BodySection
            alignment={'left'}
            sectionClassName={'Section-3'}
            headerClassName={'Section-3-Header'}
            header={'Advancement'}
            carouselHeader={'Career Pathways'}
            sectionData={HomeData.advancement}
            paragraph={
              homePageData
                ? JSON.parse(homePageData.paragraphs)[3][1]
                : 'Loading'
            }
            data={this.props.data}
            buttonText={'Learn More'}
            buttonClassName={'Learn-More-Button-Blue'}
            carouselWidth={'wide'}
          />
        </div>
        <HomeComponents.Paragraph
          headerClassName={'Paragraph-Header'}
          containerClassName={'Paragraph-Container'}
          leftContainerClassName={'Paragraph-Left'}
          rightContainerClassName={'Paragraph-Right'}
          paragraphClassName={'Paragraph-Paragraph'}
          header={'Raise the Bar'}
          paragraph={
            homePageData ? JSON.parse(homePageData.paragraphs)[4][1] : 'Loading'
          }
          buttonText={'Learn More'}
          buttonDestination={'/online-courses'}
          buttonClassName={'Learn-More-Button-White'}
          imgClassName={'Paragraph-Image'}
          imgSrc={raiseBar}
          imgAlt={'Raise'}
        />
        <JumbotronPhoto
          buttonDestination={'/entry'}
          buttonText={'Explore Opportunities'}
          paragraph={
            homePageData ? JSON.parse(homePageData.paragraphs)[5][1] : 'Loading'
          }
          data={HomeData.findPathways}
          sectionClassName={'Jumbotron-Photo-1'}
        />
      </div>
    );
  }
}

export default HomePage;

import React, {Component} from 'react';
import './LoadingPage.scss';
import ReactGA from 'react-ga4';
class LoadingPage extends Component {
  render() {
    ReactGA.send({hitType: 'pageview', page: '/loading', title: 'Loading'});
    return (
      <div className={'Loading-Page-Container'}>
        <div className='Loading-Page-Text'>Loading Career Pathways</div>
      </div>
    );
  }
}

export default LoadingPage;

import React, {useState, useEffect} from 'react';
// import * as d3 from 'd3';
import './CircleChart.scss';
import AnimateOnScroll from '../../../logic/AnimateOnScroll';
import Chart from './Chart';
/**
 *
 * @param {props.pathway} Pathway Data
 * @returns Circle Donut Chart
 */
export default function CircleChart(props) {
  const [animatechart, setAnimateChart] = useState(0);
  const [ref, visible] = AnimateOnScroll({rootMargin: '-50px'});
  const [chartStyle, setChartStyle] = useState();
  const width = window.innerWidth;
  useEffect(() => {
    if (visible) {
      setAnimateChart(1);
    } else {
      setAnimateChart(0);
    }
  }, [setAnimateChart, visible]);
  useEffect(() => {
    if (width > 786) {
      setChartStyle('Chart-Container');
    } else if (width <= 786) {
      setChartStyle('Chart-Container-Mobile');
    }
  }, [width]);
  return (
    <div ref={ref} animatechart={animatechart} className={chartStyle}>
      <Chart pathway={props.pathway} />
      <div className='Chart-Text'>Work Process Schedule</div>
    </div>
  );
}

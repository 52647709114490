import React, {useState, useEffect} from 'react';
import './ProgramTimeline.scss';
import TimelineChart from './TimelineChart';
import AnimateOnScroll from '../../../logic/AnimateOnScroll';
import TimelineChartMobile from './TimelineChartMobile';

function ProgramTimeline(props) {
  /**
   * ProgramTimeline Functional Component
   * *This component renders the Timeline Chart section of each program page.
   * *Imports both a TimelineChart for desktop and a TimelineChartMobile for smaller screens and phones.
   * *Features a useEffect component that checks when the window is resized and updates the innerWidth state. This is used to determine if the regular TimelineChart should render or the mobile version based on screen width.
   * *Utilizes props to populate content
   */
  const [animatetimelineheading, setAnimateTimelineHeading] = useState(0);
  const [ref, visible] = AnimateOnScroll({rootMargin: '50px'});
  const [innerWidth, setInnerWidth] = useState('');
  useEffect(() => {
    if (visible === true) {
      setAnimateTimelineHeading(1);
    }
    return () => {
      setAnimateTimelineHeading(0);
    };
  }, [visible]);
  useEffect(() => {
    handleResize();
    function handleResize() {
      setInnerWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
  }, [innerWidth]);
  return (
    <div className='Program-Timeline'>
      <div className='Program-Timeline-Content-Container'>
        <div className='Timeline-Headings-Container'>
          <h2
            className='Timeline-Heading-One'
            ref={ref}
            animatetimelineheading={animatetimelineheading}
            style={{color: props.timelineHeading1Color}}
          >
            POTENTIAL CAREER PATHWAYS
          </h2>
          <h1
            className='Timeline-Heading-Two'
            ref={ref}
            animatetimelineheading={animatetimelineheading}
            style={{color: props.timelineHeading2Color}}
          >
            {props.timelineHeading2}
          </h1>
          <p
            className='Timeline-Details'
            ref={ref}
            animatetimelineheading={animatetimelineheading}
          >
            {props.timelineDetails}
          </p>
        </div>
        <div className='Timeline-Chart'>
          {innerWidth <= '800' ? (
            <TimelineChartMobile timelineType={props.timelineLevel} />
          ) : (
            <TimelineChart timelineType={props.timelineLevel} />
          )}
        </div>
      </div>
    </div>
  );
}

export default ProgramTimeline;

import React from 'react';
import Button from '../../../widgets/Button';
import './ProgramCareerPathways.scss';
import PreloadImage from 'react-preload-image';

function CareerCard(props) {
  /**
   * CareerCard functional component.
   * *This functional component is imported to ProgramCareerPathways and is used to populate cards in it's container based on occupation.
   * *Props are integrated into this as it gives the button of each card a place to send the user to its specific pathway page, as well as determining its title, details, and the source of its image.
   */
  return (
    <div className='Career-Card'>
      <PreloadImage src={props.source} className='Career-Card-SVG' alt='cardPicture' />
      <div className='Career-Card-Content-Container'>
        <div className='Career-Card-Content'>
          <h3 className='Career-Card-Heading'>{props.cardTitle}</h3>
          <p className='Career-Card-Text'>{props.cardDetails}</p>
          {props.buttonDestination !== undefined ? (
            <Button
              to={props.buttonDestination}
              className='Career-Card-Button'
              text='VIEW CAREER'
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default CareerCard;

import React, {Component} from 'react';
import './NetworkErrorPage.scss';
class NetworkErrorPage extends Component {
  render() {
    return (
      <div className={'Network-Error-Page-Container'}>
        <div className={'Network-Error-Container'}>
          <div className='Network-Error-Page-Text'>
            Network Error, Please Come Back Later
          </div>
          <a className='Network-Error-Page-Button' href='/'>
            Reload
          </a>
        </div>
      </div>
    );
  }
}

export default NetworkErrorPage;

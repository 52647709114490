import React from 'react';
import './AccordionBoard.scss';
import CallToAction from './CallToAction';
import Shelf from './Shelf';
/**
 * * Accordion Board System
 * @param {props.pdfData} PDF Data
 * @param {props.pathway} Pathway Data
 * @returns Accordion Board of Grouped and Counted Competencies
 */
export default function AccordionBoard(props) {
  const cleanPdfs = [];
  const resources = JSON.parse(props.pathway.resourceData);
  var disclaimer = resources.filter((res) => res.includes('*'));
  const data = JSON.parse(props.pathway.competencyData);
  /**
   * * PDF Cleaner
   */
  if (props.pdfData.length > 0) {
    props.pdfData.forEach((prov) => {
      cleanPdfs.push(prov);
    });
  }
  /**
   ** Competency Sorting and Reducing
   * This adds up all the competencies by type.
   */
  var reducedData = data.reduce((b, c) => {
    return (
      (
        b[b.findIndex((d) => d.type === c.type)] ||
        b[
          b.push({
            type: c.type,
            count: 0,
          }) - 1
        ]
      ).count++,
      b
    );
  }, []);
  /**
   * * Group Competencies By Type
   * @param {Competency Data} xs
   * @param {Column to Group By} key
   * @returns Grouped Data Based on Type
   */
  var groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x.competency);
      return rv;
    }, {});
  };
  return (
    <div className={'Accordion-Board'}>
      <div className='Accordion-Board-Container'>
        {reducedData.map((comp) => {
          if (
            comp.type.length > 0 &&
            !comp.type.toLowerCase().includes('work process schedule') &&
            !comp.type.toLowerCase().includes('essential job categories')
          ) {
            return (
              <Shelf
                key={Math.random(100000000000)}
                details={groupBy(data, 'type')[comp.type]}
                title={comp.type}
                count={comp.count}
              />
            );
          } else {
            return null;
          }
        })}
      </div>
      <div className={'Disclaimer'}>{disclaimer}</div>
      <CallToAction header={'Looking for Customizable Training Tools?'} cleanPdfs={cleanPdfs} pathway={props.pathway} />
    </div>
  );
}

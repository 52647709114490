/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react';
// Import HTTPS REDIRECT
import HttpsRedirect from 'react-https-redirect';
// Import Router and Logic
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import ScrollToTop from './app/logic/ScrollToTop';
// Import App Styles
import './App.scss';
// Import Pages
import * as Pages from './app/pages';
import {Footer} from './app/components';
// Import Google Analytics
import ReactGA from 'react-ga4';
import googleConfig from './config/Google';
ReactGA.initialize(googleConfig.analyticsID);
// import Translator from './app/logic/Translator';
/**
 *
 * @returns Application
 */
export default function App() {
  const [pdfData, setPDFData] = useState([]);
  const [pathwayData, setPathwayData] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [courseData, setCourseData] = useState([]);
  const [dataStatus, setDataStatus] = useState('');
  /**
   *
   * On Load, This useEffect Gets App Data from Mongo DB
   * and clears the data when it unmounts.
   *
   */
  useEffect(() => {
    // const startTime = performance.now(); -- FOR TESTING SPEEDS
    if (dataStatus === '') {
      try {
        getAllData().then(() => {
          setDataStatus('loaded');
        // const duration = performance.now() - startTime; -- FOR TESTING SPEEDS
        // console.log(`Getting Data took ${duration}ms`); -- FOR TESTING SPEEDS
        });
      } catch (err) {
        setDataStatus('error');
      }
    }
    return () => {
      clearData();
    };
  }, []);
  /**
   *
   * @return Clear All Data
   *
   */
  const clearData = () => {
    setPathwayData([]);
    setPageData([]);
    setPDFData([]);
    setCourseData([]);
  };
  /**
   *
   * @returns All of the App Data from Mongo DB
   *
   */
  const getAllData = async () => {
    setDataStatus('loading');
    var allDataUrl =
      'https://us-east-1.aws.data.mongodb-api.com/app/career-pathways-mqcqx/endpoint/pathways_data';
    try {
      await fetch(allDataUrl, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        // credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
        },
        redirect: 'follow', // manual, *follow, error
        // referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        // body: JSON.stringify(data), // body data type must match "Content-Type" header
      }).then((response) => {
        const allData = response.json();
        allData 
          .then((data) => {
            if (data) {
              setPageData(data.pages);
              setPathwayData(data.pathways);
              setCourseData(data.courses);
              setPDFData(data.pdfs);
              setDataStatus('loaded');
            } else {
              setDataStatus('error');
            }
          })
          .catch(() => {
            setDataStatus('error');
          });
      });
    } catch (error) {
      setDataStatus('error');
    }
  };
  
  /**
   *
   * Application Content
   *
   */
  function AppContent() {
    return (
      <HttpsRedirect>
        <div className='AppContainer'>
          <Router>
            <ScrollToTop>
              <Routes>
                <Route exact path='/'
                  element={
                    <Pages.HomePage
                      pageData={pageData}
                      pathwayData={pathwayData}
                    />
                  }
                />
                <Route exact path='/about'
                  element={
                    <Pages.AboutPage
                      pageData={pageData}
                    />
                  }
                />
                <Route exact path='/online-courses'
                  element={
                    <Pages.CoursesPage
                      courseData={courseData}
                    />
                  }
                />
                <Route exact path='/contact'
                  element={
                    <Pages.ContactPage
                      pageData={pageData}
                    />
                  }
                />
                <Route exact path='/entry'
                  element={
                    <Pages.EntryPage
                      pageData={pageData}
                      pathwayData={pathwayData}
                    />
                  }
                />
                <Route exact path='/career'
                  element={
                    <Pages.CareerPage pageData={pageData}
                      pathwayData={pathwayData}
                    />
                  }
                />
                <Route exact path='/advancement'
                  element={
                    <Pages.AdvancementPage
                      pageData={pageData}
                      pathwayData={pathwayData}
                    />
                  }
                />
                <Route exact path='/ev-career'
                  element={
                    <Pages.EVCareerPage pageData={pageData}
                      pathwayData={pathwayData}
                    />
                  }
                />
                {pathwayData.length > 0
                  ? pathwayData.map((pathway, index) => {
                    var nextPathway =
                      index !== pathwayData.length
                        ? pathwayData[index + 1]
                        : pathwayData[0];
                    var condensedOccupationTitle = pathway.occupation.replace(
                      /\s/g,
                      '-'
                    ).replace(/\(|\)/g, '');
                    const occupationRoute = JSON.parse(
                      condensedOccupationTitle
                    );
                    return (
                      <Route
                        key={Math.round(Math.random(100000), 2)}
                        exact
                        path={`/${occupationRoute}`}
                        element={<Pages.PathwayPage
                          pathway={pathway}
                          pdfData={pdfData}
                          nextPathway={nextPathway}
                          title={pathway.occupation.replace(/<\/?[^>]+>/gi, '')}
                          summary={pathway.description.replace(
                            /<\/?[^>]+>/gi,
                            ''
                          )}
                        />}
                      />
                    );
                  })
                  : null}
                <Route path="*" exact
                  element={<Pages.ErrorPage />}
                />
              </Routes>
              <Footer />
            </ScrollToTop>
          </Router>
        </div>
      </HttpsRedirect>
    );
  }
  /**
   *
   * @returns Application if there are no errors.
   *
   */
  if (dataStatus === 'loaded') {
    return <AppContent />;
  } else if (dataStatus === 'error') {
    return <Pages.NetworkErrorPage />;
  } else if (dataStatus === 'loading') {
    return <Pages.LoadingPage />;
  } else {
    return <Pages.NetworkErrorPage />;
  }
}

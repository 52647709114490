import React, {useState, useEffect} from 'react';
import './ProgramCareerPathways.scss';
import CareerCard from './CareerCard';
import AnimateOnScroll from '../../../logic/AnimateOnScroll';
import {v4 as uuidv4} from 'uuid';

function ProgramCareerPathways(props) {
  /**
   * ProgramCareerPathways functional component.
   * *This function component renders the Career Pathway component of each Program page including a map of cards.
   * *The component uses props to populate text and content as well as data from the API.
   * *Features a function that takes all names of pathwayData from props, breaks it down, reformats it and compares it to the names of images for that sepecific occupation. This also maps out multiple cards by name.
   * Comment to push this commit
   */
  const [careeranimate, setCareerAnimate] = useState(0);
  const [ref, visible] = AnimateOnScroll({rootMargin: '200px'});
  useEffect(() => {
    if (visible === true) {
      setCareerAnimate(1);
    }
    return () => {
      setCareerAnimate(0);
    };
  }, [visible]);
  const filteredPathways = props.pathwayData.filter(
    (path) => JSON.parse(path.level) === props.level
  );
  return (
    <div className='Program-Career' id={props.id}>
      <div
        className='Program-Career-Text-Container'
        ref={ref}
        careeranimate={careeranimate}
      >
        <h1
          className='Program-Career-Heading-2'
          style={{color: props.careerPathwaysHeadingColor}}
        >
          {props.careerPathwaysHeading}
        </h1>
        <p className='Program-Pathway-Details'>{props.pathwayDetails}</p>
      </div>
      <div className='Career-Card-Holder'>
        <div className='Reveal-Block' ref={ref} careeranimate={careeranimate} />
        {filteredPathways.map((pathway) => {
          // Condense
          var condensedOccupationTitle = JSON.parse(
            pathway.occupation.replace(/\s/g, '-')
          );
          // Remove Parenthesis
          var destination = condensedOccupationTitle.replace(/[{()}]/g, '');
          //Formatting the occupation title into an image source
          var sourceOcc = destination.toLowerCase().replace(/\//g, '-');
          //Remove parenthesis, what's inside them, and parse the occupation to be used for the card title.
          var cardTitle = JSON.parse(
            pathway.occupation.replace(/\([^()]*\)/g, '')
          );
          return (
            <CareerCard
              buttonDestination={`/${destination}`}
              cardTitle={cardTitle}
              source={'/assets/png/' + sourceOcc + '.png'}
              key={uuidv4()}
            />
          );
        })}
      </div>
    </div>
  );
}

export default ProgramCareerPathways;

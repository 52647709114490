import React, {useEffect, useState} from 'react';
import AnimateOnScroll from '../../logic/AnimateOnScroll';
import './SVGTriangle.scss';
export default function SVGTriangle(props) {
  const [slideintriangle, setslideintriangle] = useState(0);
  const [ref, visible] = AnimateOnScroll({rootMargin: '-50px'});
  useEffect(() => {
    if (visible) {
      setslideintriangle(1);
    } else {
      setslideintriangle(0);
    }
  }, [visible]);
  return (
    <svg className={props.svgClassName}>
      <polygon
        ref={ref}
        slideintriangle={slideintriangle}
        className={props.polygonClassName}
        points={props.polygonPoints}
      />
    </svg>
  );
}

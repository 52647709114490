import React from 'react';
// Import React Google Analytics
import ReactGA from 'react-ga4';
/**
 *
 * @param {props.buttonType} Handles Button Type
 * @param {props.className} Button Class Name
 * @param {props.to} Destination for Button
 * @returns Button Widget
 */
export default function Button(props) {
  if (props.buttonType === 'link') {

    return (
      <a
        onClick={() => {
          ReactGA.event({
            category: 'Button',
            action: 'Clicked Link Button',
            label: props.to,
          });
        }}
        target='_blank'
        rel='noopener noreferrer'
        href={`${props.to}`}
        className={props.className}
      >
        {props.text}
      </a>
    );
  } else {

    return (
      <button
        onClick={() => {
          ReactGA.event({
            category: 'Button',
            action: 'Clicked Button',
            label: `${props.className}, ${props.to}`, 
          });
          window.location = props.to;
        }}
        className={props.className}
      >
        {props.text}
      </button>
    );
  }
}

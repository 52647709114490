import React, {Component} from 'react';
import * as d3 from 'd3';
/**
 * * Chart Component for Rendering Donut Circle on the Pathway Pages.
 * This Component uses D3 in a componentDidMount to render the circle chart
 * with data passed down from the very top of the app with prop drilling.
 * TODO: Make Circle Chart Fully Responsive, not just on load.
 * @param {props.pathway} Pathway Data
 */
export class Chart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pathway: props.pathway,
      chartWidth: window.innerWidth,
      chartHeight: window.innerHeight,
      margin:
        window.innerWidth > 850
          ? window.innerWidth * 0.1
          : window.innerWidth * 0.28,
    };
  }
  componentDidMount() {
    // Create dummy data
    var data = JSON.parse(this.state.pathway.competencyData);
    var reducedData = data.reduce((b, c) => {
      return (
        (
          b[b.findIndex((d) => d.type === c.type)] ||
          b[b.push({type: c.type, count: 0}) - 1]
        ).count++,
        b
      );
    }, []);
    // set the dimensions and margins of the graph
    // The radius of the pieplot is half the width or half the height (smallest one). I subtract a bit of margin.
    var radius =
      Math.min(this.state.chartWidth, this.state.chartHeight) / 2 -
      this.state.margin;
    // Select Chart
    var chart = d3.select('.Chart');
    // append the svg object to the div called 'my_dataviz'
    var svg = chart
      .append('svg')
      .attr('id', 'Chart-SVG')
      .attr('width', this.state.chartWidth)
      .attr('height', this.state.chartHeight)
      .append('g')
      .attr(
        'transform',
        `translate(${this.state.chartWidth / 2}, ${this.state.chartHeight / 2})`
      );
    // set the color scale
    var color = d3
      .scaleOrdinal()
      .domain(reducedData)
      .range([
        '#00A8E5',
        '#6CB706',
        '#004C5E',
        '#006E8B',
        '#90D12A',
        '#B8EA60',
      ]);
    // Compute the position of each group on the pie:
    var pie = d3
      .pie()
      .sort(null) // Do not sort group by size
      .value(function (d) {
        if (
          d[1].type &&
          !d[1].type.toLowerCase().includes('work process schedule') &&
          !d[1].type.toLowerCase().includes('essential job categories')
        ) {
          return d[1].count;
        }
      });
    var data_ready = pie(Object.entries(reducedData));
    // The arc generator
    var arc = d3
      .arc()
      .innerRadius(radius * 0.7) // This is the size of the donut hole
      .outerRadius(radius * 0.8);
    // Another arc that won't be drawn. Just for labels positioning
    var outerArc = d3
      .arc()
      .innerRadius(radius * 0.8)
      .outerRadius(radius * 0.8);
    // Build the pie chart: Basically, each part of the pie is a path that we build using the arc function.
    svg
      .selectAll('allSlices')
      .data(data_ready)
      .enter()
      .append('path')
      .attr('d', arc)
      .attr('fill', function (d) {
        if (
          d.data[1].type.length > 0 &&
          !d.data[1].type.toLowerCase().includes('work process schedule') &&
          !d.data[1].type.toLowerCase().includes('essential job categories')
        ) {
          return color(d.data[0]);
        }
      })
      .attr('stroke', 'white')
      .attr('class', 'Circle-Path')
      .style('stroke-width', '0px')
      .style('opacity', 1);
    // Add the polylines between chart and labels:
    svg
      .selectAll('All-Polylines')
      .data(data_ready)
      .enter()
      .append('polyline')
      .attr('stroke', 'black')
      .style('fill', 'none')
      .attr('stroke-width', 1)
      .attr('points', function (d) {
        if (
          d.data[1].type.length > 0 &&
          !d.data[1].type.toLowerCase().includes('work process schedule') &&
          !d.data[1].type.toLowerCase().includes('essential job categories')
        ) {
          var posA = arc.centroid(d); // line insertion in the slice
          var posB = outerArc.centroid(d); // line break: we use the other arc generator that has been built only for that
          var posC = outerArc.centroid(d); // Label position = almost the same as posB
          var midangle = d.startAngle + (d.endAngle - d.startAngle) / 2; // we need the angle to see if the X position will be at the extreme right or extreme left
          posC[0] = radius * 0.95 * (midangle < Math.PI ? 1 : -1); // multiply by 1 or -1 to put it on the right or on the left
          return [posA, posB, posC];
        }
      });
    // Add the polylines between chart and labels:
    svg
      .selectAll('#Chart-Label')
      .data(data_ready)
      .enter()
      .append('text')
      .attr('id', 'Chart-Label')
      .attr('key', function (d) {
        if (d.data[1].type.length > 0) {
          return d.data[0];
        }
      })
      .attr('fill', function (d) {
        if (d.data[1].type.length > 0) {
          return color(d.data[0]);
        }
      })
      .text(function (d) {
        if (
          d.data[1].type.length > 0 &&
          !d.data[1].type.toLowerCase().includes('work process schedule') &&
          !d.data[1].type.toLowerCase().includes('essential job categories')
        ) {
          if (window.innerWidth > 700) {
            return `${d.data[1].type}, ${d.data[1].count} Competencies`;
          } else if (window.innerWidth <= 700) {
            return `${d.data[1].type}`;
          }
        }
      })
      .attr('id', 'Chart-Label-Text')
      .attr('transform', function (d) {
        var pos = outerArc.centroid(d);
        var midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
        pos[0] = radius * 0.99 * (midangle < Math.PI ? 1 : -1);
        return `translate(${pos})`;
      })
      .style('text-anchor', function (d) {
        var midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
        return midangle < Math.PI ? 'start' : 'end';
      });

    // // Plot Sub Labels
    // svg
    //   .selectAll('Chart-Sub-Label')
    //   .data(data_ready)
    //   .enter()
    //   .append('text')
    //   .text(function (d) {
    //     return `${d.data[1].count} Competencies`;
    //   })
    //   .attr('id', 'Chart-Sub-Label')
    //   .attr('transform', function (d) {
    //     var pos = outerArc.centroid(d);
    //     var midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
    //     pos[0] = (radius * 0.3 * (midangle < Math.PI ? 2.4 : -2.8)) / 2;
    //     return `translate(${pos})`;
    //   })
    //   .style('text-anchor', function (d) {
    //     var midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
    //     return midangle < Math.PI ? 'start' : 'end';
    //   });
  }
  render() {
    return <div className='Chart' />;
  }
}

export default Chart;

import React, {useState, useEffect} from 'react';
import AnimateOnScroll from '../../../logic/AnimateOnScroll';

function TimelineChartMobile(props) {
  /**
   * TimelineChartMobile Functional Component.
   * *This functional component renders the mobile version of the TimelineChart.
   * *Uses Animate on Scroll with refs and a state to animate svg lines by adding a ghost element that reveals them.
   * *Uses a window resize listener tied to a state to switch out an svg line for a condensed one on the decrease of window width.
   * *Simple If statement conditionals are used to specify which timeline is to be used which is determined by a prop giving the timelineType ex: (Entry, Career, Advancement).
   */
  const [innerWidth, setInnerWidth] = useState('');
  const [reveallinesmobile, setRevealLinesMobile] = useState(0);
  const [ref, visible] = AnimateOnScroll({rootMargin: '-150px'});
  useEffect(() => {
    handleResize();
    function handleResize() {
      setInnerWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
  }, [innerWidth]);

  useEffect(() => {
    if (visible === true) {
      setRevealLinesMobile(1);
    }
    return () => {
      setRevealLinesMobile(0);
    };
  }, [visible]);
  if (props.timelineType === 'entry') {
    return (
      <div className='Mobile-Timeline-Charts-Container' ref={ref}>
        <div
          className='Reveal-Cards-Block-Mobile'
          reveallinesmobile={reveallinesmobile}
        />
        <div className='Timeline-Mobile-Chart-Container'>
          <div className='Timeline-Mobile-Card-First-Green'>
            <a id='mobileLinkUp' href='/Assembler' >
              <h2 className='Timeline-Mobile-Card-First-Heading-1'>entry</h2>
              <hr></hr>
              <h1 className='Timeline-Mobile-Card-First-Heading-2'>Assembler</h1>
            </a>
          </div>
          <svg className='Mobile-Line-1-SVG'>
            <path
              className='Line-Path-Green'
              d='
          M 55, 0
          L 55, 400
          '
            />
          </svg>
          <svg className='Mobile-Line-2-SVG'>
            <path
              className='Line-Path-Green'
              d='
          M 55, 0
          L 55, 700
          '
            />
          </svg>
          <div
            className='Reveal-Cards-Block-Mobile'
            ref={ref}
            reveallinesmobile={reveallinesmobile}
          />
          <div className='Mobile-Career-Card-First-Container'>
            <div className='Timeline-Mobile-Card'>
              <a id='mobileLinkUp' href='/Industrial-Engineering-Technician' >
                <h2 className='Timeline-Mobile-Card-Heading-1'>career</h2>
                <hr></hr>
                <h1 className='Timeline-Mobile-Card-Heading-2'>
                    Industrial Engineering Technician
                </h1>
              </a>
            </div>
            <svg className='Mobile-Line-3-SVG'>
              <path
                className='Line-Path-Green'
                d='
          M 55, 0
          L 55, 600
          '
              />
            </svg>
            <div className='Mobile-Career-Card-Row-Bottom'>
              <div className='Timeline-Mobile-Card'>
                <a id='mobileLinkUp' href='/Industrial-Maintenance-Mechanic' >
                  <h2 className='Timeline-Mobile-Card-Heading-1'>career</h2>
                  <hr></hr>
                  <h1 className='Timeline-Mobile-Card-Heading-2'>
                    Industrial Maintenance Mechanic
                  </h1>
                </a>
              </div>
            </div>
          </div>
          <div className='Mobile-Advance-Card'>
            <a id='mobileLinkUp' href='/Industrial-Engineer' >
              <h2 className='Timeline-Mobile-Card-Heading-1'>Advancement</h2>
              <hr></hr>
              <h1 className='Timeline-Mobile-Card-Heading-2'>
                Industrial Engineer
              </h1>
            </a>
          </div>
        </div>
        <div className='Timeline-Mobile-Chart-Container'>
          <div
            className='Timeline-Mobile-Card-First-Blue'
            ref={props.ref}
            animatetimeline={props.animatetimeline}
          >
            <a id='mobileLinkUp' href='/Picker/Packer' >
              <h2 className='Timeline-Mobile-Card-First-Heading-1'>entry</h2>
              <hr></hr>
              <h1 className='Timeline-Mobile-Card-First-Heading-2'>
                Picker/Packer
              </h1>
            </a>
          </div>
          <svg className='Mobile-Line-4-SVG'>
            <path
              className='Line-Path-Blue'
              d='
          M 55, 0
          L 55, 400
          '
            />
          </svg>
          <div className='Mobile-Career-Card-Second-Container'>
            <div className='Timeline-Mobile-Card'>
              <a id='mobileLinkUp' href='/Tool-and-Die-Maker' >
                <h2 className='Timeline-Mobile-Card-Heading-1'>career</h2>
                <hr></hr>
                <h1 className='Timeline-Mobile-Card-Heading-2'>
                  Tool and Die Maker
                </h1>
              </a>
            </div>
            <svg className='Mobile-Line-5-SVG'>
              <path
                className='Line-Path-Blue'
                d='
          M 55, 0
          L 55, 600
          '
              />
            </svg>
          </div>
          <div className='Mobile-Advance-Card'>
            <a id='mobileLinkUp' href='/Production/Operation-Manager' >
              <h2 className='Timeline-Mobile-Card-Heading-1'>Advancement</h2>
              <hr></hr>
              <h1 className='Timeline-Mobile-Card-Heading-2'>
                Production/Operation Manager
              </h1>
            </a>
          </div>
        </div>
      </div>
    );
  } else if (props.timelineType === 'career') {
    return (
      <div className='Mobile-Timeline-Charts-Container' ref={ref}>
        <div
          className='Reveal-Cards-Block-Mobile'
          reveallinesmobile={reveallinesmobile}
        />
        <div className='Timeline-Mobile-Chart-Container'>
          <div
            className='Timeline-Mobile-Card-First-Green'
            ref={props.ref}
            animatetimeline={props.animatetimeline}
          >
            <a id='mobileLinkUp' href='/Material-Handler' >
              <h2 className='Timeline-Mobile-Card-First-Heading-1'>entry</h2>
              <hr></hr>
              <h1 className='Timeline-Mobile-Card-First-Heading-2'>
                Material Handler
              </h1>
            </a>
          </div>
          <svg className='Mobile-Line-1-SVG'>
            <path
              className='Line-Path-Green'
              d='
        M 55, 0
        L 55, 400
        '
            />
          </svg>
          <svg className='Mobile-Line-2-SVG'>
            <path
              className='Line-Path-Green'
              d='
        M 55, 0
        L 55, 700
        '
            />
          </svg>
          <div className='Mobile-Career-Card-First-Container'>
            <div className='Timeline-Mobile-Card'>
              <a id='mobileLinkUp' href='/CNC-Machine-Operator' >
                <h2 className='Timeline-Mobile-Card-Heading-1'>career</h2>
                <hr></hr>
                <h1 className='Timeline-Mobile-Card-Heading-2'>
                  CNC Machine Operator
                </h1>
              </a>
            </div>
            <svg className='Mobile-Line-3-SVG' style={{top: '47vw'}}>
              <path
                className='Line-Path-Green'
                d='
        M 55, 0
        L 55, 600
        '
              />
            </svg>
            <div
              className='Mobile-Career-Card-Row-Bottom'
              style={{top: '25vw'}}
            >
              <div className='Timeline-Mobile-Card'>
                <a id='mobileLinkUp' href='/Robotics-Technician' >
                  <h2 className='Timeline-Mobile-Card-Heading-1'>career</h2>
                  <hr></hr>
                  <h1 className='Timeline-Mobile-Card-Heading-2'>
                    Robotics Technician
                  </h1>
                </a>
              </div>
            </div>
          </div>
          <div className='Mobile-Advance-Card'>
            <a id='mobileLinkUp' href='/Frontline-Supervisor/Foreman' >
              <h2 className='Timeline-Mobile-Card-Heading-1'>Advancement</h2>
              <hr></hr>
              <h1 className='Timeline-Mobile-Card-Heading-2'>
                Frontline Supervisor/Foreman
              </h1>
            </a>
          </div>
        </div>
        <div className='Timeline-Mobile-Chart-Container'>
          <div
            className='Timeline-Mobile-Card-First-Blue'
            ref={props.ref}
            animatetimeline={props.animatetimeline}
          >
            <a id='mobileLinkUp' href='/Production-Helper' >
              <h2 className='Timeline-Mobile-Card-First-Heading-1'>entry</h2>
              <hr></hr>
              <h1 className='Timeline-Mobile-Card-First-Heading-2'>
                Production Helper
              </h1>
            </a>
          </div>
          <svg
            className='Mobile-Line-1-SVG'
            style={{top: '190vw', right: '30vw'}}
          >
            <path
              className='Line-Path-Blue'
              d='
        M 55, 0
        L 55, 400
        '
            />
          </svg>
          <svg
            className='Mobile-Line-2-SVG'
            style={{top: '190vw', left: '15vw'}}
          >
            <path
              className='Line-Path-Blue'
              d='
        M 55, 0
        L 55, 700
        '
            />
          </svg>
          <div className='Mobile-Career-Card-First-Container-Flipped'>
            <div className='Timeline-Mobile-Card'>
              <a id='mobileLinkUp' href='/Plastic-Process-Technician' >
                <h2 className='Timeline-Mobile-Card-Heading-1'>career</h2>
                <hr></hr>
                <h1 className='Timeline-Mobile-Card-Heading-2'>
                  Plastic Process Technician
                </h1>
              </a>
            </div>
            <svg className='Mobile-Line-3-SVG' style={{left: '20vw'}}>
              <path
                className='Line-Path-Blue'
                d='
        M 55, 0
        L 55, 600
        '
              />
            </svg>
            <div className='Mobile-Career-Card-Row-Bottom-2'>
              <div className='Timeline-Mobile-Card'>
                <a id='mobileLinkUp' href='/CNC-Set-up-Programmer' >
                  <h2 className='Timeline-Mobile-Card-Heading-1'>career</h2>
                  <hr></hr>
                  <h1 className='Timeline-Mobile-Card-Heading-2'>
                    CNC Set-up Programmer
                  </h1>
                </a>
              </div>
            </div>
          </div>
          <div className='Mobile-Advance-Card'>
            <a id='mobileLinkUp' href='/Production/Operation-Manager' >
              <h2 className='Timeline-Mobile-Card-Heading-1'>Advancement</h2>
              <hr></hr>
              <h1 className='Timeline-Mobile-Card-Heading-2'>
                Production/Operation Manager
              </h1>
            </a>
          </div>
        </div>
      </div>
    );
  } else if (props.timelineType === 'advancement') {
    return (
      <div className='Mobile-Timeline-Charts-Container' ref={ref}>
        <div
          className='Reveal-Cards-Block-Mobile'
          reveallinesmobile={reveallinesmobile}
        />
        <div className='Timeline-Mobile-Chart-Container'>
          <div
            className='Timeline-Mobile-Card-First-Green'
            ref={props.ref}
            animatetimeline={props.animatetimeline}
          >
            <a id='mobileLinkUp' href='/Material-Handler' >
              <h2 className='Timeline-Mobile-Card-First-Heading-1'>entry</h2>
              <hr></hr>
              <h1 className='Timeline-Mobile-Card-First-Heading-2'>
                Material Handler
              </h1>
            </a>
          </div>         
          <svg className='Mobile-Line-1-SVG'>
            <path
              className='Line-Path-Green'
              d='
        M 55, 0
        L 55, 400
        '
            />
          </svg>
          <svg className='Mobile-Line-2-SVG'>
            <path
              className='Line-Path-Green'
              d='
        M 55, 0
        L 55, 700
        '
            />
          </svg>
          <div className='Mobile-Career-Card-First-Container'>
            <div className='Timeline-Mobile-Card'>
              <a id='mobileLinkUp' href='/Welder' >
                <h2 className='Timeline-Mobile-Card-Heading-1'>career</h2>
                <hr></hr>
                <h1 className='Timeline-Mobile-Card-Heading-2'>Welder</h1>
              </a>
            </div>
            <svg className='Mobile-Line-3-SVG' style={{top: '47vw'}}>
              <path
                className='Line-Path-Green'
                d='
        M 55, 0
        L 55, 600
        '
              />
            </svg>
            <div
              className='Mobile-Career-Card-Row-Bottom'
              style={{top: '25vw'}}
            >
              <div className='Timeline-Mobile-Card'>
                <a id='mobileLinkUp' href='/Fabricator-Structural-Metal-Fabricators-and-Fitters' >
                  <h2 className='Timeline-Mobile-Card-Heading-1'>career</h2>
                  <hr></hr>
                  <h1 className='Timeline-Mobile-Card-Heading-2'>Fabricator</h1>
                </a>
              </div>
            </div>
          </div>
          <div className='Mobile-Advance-Card'>
            <a id='mobileLinkUp' href='/Frontline-Supervisor/Foreman' >
              <h2 className='Timeline-Mobile-Card-Heading-1'>Advancement</h2>
              <hr></hr>
              <h1 className='Timeline-Mobile-Card-Heading-2'>
                Frontline Supervisor/Foreman
              </h1>
            </a>
          </div>
        </div>
        <div className='Timeline-Mobile-Advancement-Bottom-Chart-Container'>
          <div
            className='Timeline-Mobile-Card-First-Blue'
            ref={props.ref}
            animatetimeline={props.animatetimeline}
          >
            <a id='mobileLinkUp' href='/Picker/Packer' >
              <h2 className='Timeline-Mobile-Card-First-Heading-1'>entry</h2>
              <hr></hr>
              <h1 className='Timeline-Mobile-Card-First-Heading-2'>
                Picker/Packer
              </h1>
            </a>
          </div>
          <svg
            className='Mobile-Line-2-SVG'
            style={{top: '190vw', left: '23vw', height: '50vw'}}
          >
            <path
              className='Line-Path-Blue'
              d='
        M 55, 0
        L 55, 390
        '
            />
          </svg>
          <div className='Mobile-Career-Card-First-Container'>
            <div className='Timeline-Mobile-Card' style={{marginLeft: '3vw'}}>
              <a id='mobileLinkUp' href='/Quality-Control-Inspector' >
                <h2 className='Timeline-Mobile-Card-Heading-1'>career</h2>
                <hr></hr>
                <h1 className='Timeline-Mobile-Card-Heading-2'>
                  Quality Control Inspector
                </h1>
              </a>
            </div>
          </div>
          <div className='Mobile-Career-Card-First-Container'>
            <div className='Timeline-Mobile-Card'>
              <a id='mobileLinkUp' href='/Quality-Technician' >
                <h2 className='Timeline-Mobile-Card-Heading-1'>career</h2>
                <hr></hr>
                <h1 className='Timeline-Mobile-Card-Heading-2'>
                  Quality Technician
                </h1>
              </a>
            </div>
            <svg className='Mobile-Line-6-SVG'>
              {innerWidth <= '413' ? (
                <path
                  className='Line-Path-Blue'
                  d='
               M 55, 0
               L 55, 380
               M 55, 60
               Q 55, 90
                 110, 100
               Q 135, 102
                 137, 117
               L 138, 600
              '
                />
              ) : (
                <path
                  className='Line-Path-Blue'
                  d='
               M 55, 0
               L 55, 380
               M 55, 60
               Q 55, 110
                 180, 100
               Q 210, 100
                 210, 135
               L 210, 600
              '
                />
              )}
            </svg>
            <div className='Ghost-Element' />
            <div className='Mobile-Advancement-Card-Row-Bottom'>
              <div className='Timeline-Mobile-Card'>
                <a id='mobileLinkUp' href='/Industrial-Engineer' >
                  <h2 className='Timeline-Mobile-Card-Heading-1'>career</h2>
                  <hr></hr>
                  <h1 className='Timeline-Mobile-Card-Heading-2'>
                    Industrial Engineer
                  </h1>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (props.timelineType === 'ev-career') {
    return (
      <div className='Mobile-Timeline-Charts-Container' ref={ref}>
        <div
          className='Reveal-Cards-Block-Mobile'
          reveallinesmobile={reveallinesmobile}
        />
        <div className='Timeline-Mobile-Chart-Container'>
          <div
            className='Timeline-Mobile-Card-First-Green'
            ref={props.ref}
            animatetimeline={props.animatetimeline}
          >
            <a id='mobileLinkUp' href='/Material-Handler' >
              <h2 className='Timeline-Mobile-Card-First-Heading-1'>entry</h2>
              <hr></hr>
              <h1 className='Timeline-Mobile-Card-First-Heading-2'>
                Material Handler
              </h1>
            </a>
          </div>
          <svg className='Mobile-Line-1-SVG'>
            <path
              className='Line-Path-Green'
              d='
        M 55, 0
        L 55, 400
        '
            />
          </svg>
          <svg className='Mobile-Line-2-SVG'>
            <path
              className='Line-Path-Green'
              d='
        M 55, 0
        L 55, 700
        '
            />
          </svg>
          <div className='Mobile-Career-Card-First-Container'>
            <div className='Timeline-Mobile-Card-EV'>
              <a id='mobileLinkUp' href='/Electrical-and-Electronic-Engineering-Technician' >
                <h2 className='Timeline-Mobile-Card-Heading-1'>career</h2>
                <hr></hr>
                <h1 className='Timeline-Mobile-Card-Heading-2-EV'>
                  Electrical and Electronic Engineering Technician
                </h1>
              </a>
            </div>
            <svg className='Mobile-Line-3-SVG' style={{top: '47vw'}}>
              <path
                className='Line-Path-Green'
                d='
        M 55, 0
        L 55, 600
        '
              />
            </svg>
            <div
              className='Mobile-Career-Card-Row-Bottom'
              style={{top: '25vw'}}
            >
              <div className='Timeline-Mobile-Card'>
                <a id='mobileLinkUp' href='/Energy-Storage-Technician' >
                  <h2 className='Timeline-Mobile-Card-Heading-1'>career</h2>
                  <hr></hr>
                  <h1 className='Timeline-Mobile-Card-Heading-2'>
                    Energy Storage Technician
                  </h1>
                </a>
              </div>
            </div>
          </div>
          <div className='Mobile-Advance-Card'>
            <a id='mobileLinkUp' href='/Frontline-Supervisor/Foreman' >
              <h2 className='Timeline-Mobile-Card-Heading-1'>Advancement</h2>
              <hr></hr>
              <h1 className='Timeline-Mobile-Card-Heading-2'>
                Frontline Supervisor/Foreman
              </h1>
            </a>
          </div>
        </div>
        <div className='Timeline-Mobile-Chart-Container'>
          <div
            className='Timeline-Mobile-Card-First-Blue'
            ref={props.ref}
            animatetimeline={props.animatetimeline}
          >
            <a id='mobileLinkUp' href='/Production-Helper' >
              <h2 className='Timeline-Mobile-Card-First-Heading-1'>entry</h2>
              <hr></hr>
              <h1 className='Timeline-Mobile-Card-First-Heading-2'>
                Production Helper
              </h1>
            </a>
          </div>
          <svg
            className='Mobile-Line-1-SVG'
            style={{top: '190vw', right: '30vw'}}
          >
            <path
              className='Line-Path-Blue'
              d='
        M 55, 0
        L 55, 400
        '
            />
          </svg>
          <svg
            className='Mobile-Line-2-SVG'
            style={{top: '190vw', left: '15vw'}}
          >
            <path
              className='Line-Path-Blue'
              d='
        M 55, 0
        L 55, 700
        '
            />
          </svg>
          <div className='Mobile-Career-Card-First-Container-Flipped'>
            <div className='Timeline-Mobile-Card-EV'>
              <a id='mobileLinkUp' href='/Electromechanical-and-Mechatronics-Technician' >
                <h2 className='Timeline-Mobile-Card-Heading-1'>career</h2>
                <hr></hr>
                <h1 className='Timeline-Mobile-Card-Heading-2-EV'>
                  Electromechanical and Mechatronics Technician
                </h1>
              </a>
            </div>
            <svg className='Mobile-Line-3-SVG' style={{left: '20vw'}}>
              <path
                className='Line-Path-Blue'
                d='
        M 55, 0
        L 55, 600
        '
              />
            </svg>
            <div className='Mobile-Career-Card-Row-Bottom-2'>
              <div className='Timeline-Mobile-Card'>
                <a id='mobileLinkUp' href='/Electrical-and-Electronic-Equipment-Assembly-Technician' >
                  <h2 className='Timeline-Mobile-Card-Heading-1'>career</h2>
                  <hr></hr>
                  <h1 className='Timeline-Mobile-Card-Heading-2'>
                    Electrical and Electronic Equipment Assembly Technician
                  </h1>
                </a>
              </div>
            </div>
          </div>
          <div className='Mobile-Advance-Card'>
            <a id='mobileLinkUp' href='/Production/Operation-Manager' >
              <h2 className='Timeline-Mobile-Card-Heading-1'>Advancement</h2>
              <hr></hr>
              <h1 className='Timeline-Mobile-Card-Heading-2'>
                Production/Operation Manager
              </h1>
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default TimelineChartMobile;

import React from 'react';
import './Schedule.scss';
import PreloadImage from 'react-preload-image';
import ScheduleImage from '../../../assets/images/webp/pexels-tiger-lily-4483610.webp';
/**
 *
 * @param {props.pathway} Pathway Data
 * @returns Schedule Section for each Pathway Page
 */
export default function Schedule(props) {
  const restrictedText = 'essential job categories';
  // Cleaning Data Arrays from Mongo String.
  const cleanData = props.pathway.competencyData.replace(/\s/g, '-');
  const dataArray = JSON.parse([cleanData]);
  var competencyTypes = dataArray.map((comp) => {
    return comp.type;
  });
  var typeArray = [];
  competencyTypes.forEach((c) => {
    if (!typeArray.includes(c)) {
      typeArray.push(c);
    }
  });
  return (
    <div className='Schedule-Container'>
      <div className='Schedule-Column-Left'>
        <div className='Schedule-Card'>
          {/* <div className='Schedule-Sub-Header'>Your Schedule</div> */}
          <div className='Schedule-Header'>Related Training Instruction</div>
          <ul className='Schedule-List'>
            {typeArray.map((comp) => {
              if (
                comp.length > 0 &&
                !comp.replace(/-/g, ' ').toLowerCase().includes(restrictedText)
              ) {
                return (
                  <li key={comp} className='Schedule-List-Item'>
                    {comp.replace(/-/g, ' ')}
                  </li>
                );
              } else {
                return null;
              }
            })}
          </ul>
          {JSON.parse(props.pathway.level) === 'Career' ? (
            <div className='Schedule-Disclaimer'>
              *For Registered Apprenticeship Programs: A minimum of 144 hours of
              related instruction per year is required in the following job
              categories.
            </div>
          ) : null}
        </div>
        <div className='Schedule-Green-Card' />
      </div>
      <div className='Schedule-Column-Right'>
        <PreloadImage src={ScheduleImage} alt='schedule' className='Schedule-Image' />
      </div>
    </div>
  );
}
